import { Box, Slider, Tooltip } from '@mui/material';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setFilterChange } from '../../../../store/homepageFilterSlice';

// Tooltip Component for MUI Slider
function ValueLabelComponent(props) {
    const { children, value } = props;

    return (
        <Tooltip enterTouchDelay={0} placement="top" title={`£${value}`}>
            {children}
        </Tooltip>
    );
}

const PriceFilter = () => {
    const initialValue = [500, 1500];
    const [value, setValue] = useState(initialValue);
    const [isChanged, setIsChanged] = useState(false);
    const dispatch = useDispatch()
    // Handle slider value change
    const handleChange = (event, newValue) => {
        setValue(newValue);
        setIsChanged(true);
        dispatch(setFilterChange({
            name: "price",
            data: true
        }))
    };

    // Handle clear text click
    const handleClear = () => {
        setValue(initialValue); // Reset to initial values
        setIsChanged(false);
        dispatch(setFilterChange({
            name: "price",
            data: false
        }))
    };

    return (
        <motion.div
            initial={{ x: 0, opacity: 0 }} // Start off-screen
            animate={{ x: 0, opacity: 1 }}    // Animate into view
            exit={{ x: 100, opacity: 0 }}     // Animate out of view
            transition={{ duration: 0.5 }}
            className="px-2" style={{ width: '352px', maxWidth: 400, margin: 'auto' }}>
            <h2 gutterBottom className='text-dark text-[12px]'>Price Range</h2>

            <Slider
                value={value}
                onChange={handleChange}
                min={399}
                max={1500}
                valueLabelDisplay="auto"
                components={{ ValueLabel: ValueLabelComponent }}
                sx={{
                    color: '#10A1D0',
                    '& .MuiSlider-thumb': {
                        borderRadius: '50%',

                    },
                    '& .MuiSlider-rail': {
                        backgroundColor: '#10A1D0',
                    },

                }}
            />
            {/* Clear Text with Animation */}
            {isChanged && (
                <motion.div
                    initial={{ opacity: 0, y: 0 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 0 }}
                    transition={{ duration: 1 }}
                    className="absolute right-0 -top-1 text-[12px]"

                >
                    <p

                        onClick={handleClear}
                        className='text-dark'

                    >
                        Clear
                    </p>
                </motion.div>
            )}
            <Box display="flex " className="text-dark text-[13px]" justifyContent="space-between" mt={0}>
                <span className='text-[#323232] text-sm'>£{value[0]}</span> -
                <span className='text-[#323232] text-sm'>£{value[1]}</span>
            </Box>
        </motion.div>
    );
};

export default PriceFilter;

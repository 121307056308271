import React from 'react';
import FinalFlightCard from './FinalFlightCard';

const FlightDetails = () => {
    return (
        <div className="w-full p-2 rounded-md min-w-[800px] bg-[#F4F5FA]">
            <div className='flex  px-3 items-center justify-between py-1.5 bg-white border-b border-gray-300'>
                <h2 className='text-sm text-primary'>
                    Flights Datails
                </h2>
                <div className='flex items-center gap-3'>
                    <img
                        src={"images/logo/union.svg"}
                        alt={""}
                        className="object-contain h-[12px] "
                    />
                    <h2 className='text-[14px] text-[#575756]'>
                        Fare Rules
                    </h2>
                </div>
            </div>

            <div className='flex flex-col w-full gap-2 p-4'>
                <FinalFlightCard isBorder />
                <FinalFlightCard />
                <div className='py-1 text-[13px] text-center bg-[#E9E9E9]'>
                    Stop over of 3 hours 30 minutes in (DEL)
                </div>
                <FinalFlightCard />

            </div>
        </div>
    );
};

export default FlightDetails;

import React from 'react';
import { TextField, MenuItem } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { DatePicker } from '@mui/lab';

export const PassengerForm = ({ formData, title, handleChange, handleBlur, errors }) => {

    const validateField = (field, value) => {
        switch (field) {
            case 'title':
                return value !== '';
            case 'firstName':
            case 'lastName':
                return value.trim() !== '';
            case 'dob':
            case 'issuedDate':
            case 'expireDate':
                return value !== '' && new Date(value) <= new Date();
            case 'passportNumber':
                return value.trim().length >= 5; // Assume minimum 5 characters
            default:
                return true;
        }
    };

    const renderCheckIcon = (field) => {
        return validateField(field, formData[field]) ? (
            <CheckCircleIcon className="bg-white" style={{ color: 'green', position: 'absolute', right: 8, top: '50%', transform: 'translateY(-50%)' }} />
        ) : null;
    };

    const customTextFieldStyles = {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#10A1D0',
            },
            '&:hover fieldset': {
                borderColor: '#10A1D0',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#10A1D0',
            },
            height: '50px',
            '& .MuiOutlinedInput-root': {
                height: '46px',
                fontSize: '16px',
                '& input': {
                    height: '46px',
                    padding: '0 14px', // Adjust padding to center the text vertically
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    borderRadius: '8px', // Optional: Adjust border radius if needed
                },
            },
        },
    };

    return (
        <div className="w-full mx-auto">
            <h2 className="mb-2 text-base text-black">{title}<span className='text-[#575756]'>{` (${formData?.passangeType})`}</span></h2>
            <form className="grid grid-cols-1 gap-2 md:grid-cols-9 md:gap-3">

                {/* Title */}
                <div className="relative flex items-center">
                    <TextField
                        select
                        label="Title"
                        value={formData.title}
                        onChange={(e) => handleChange(e, 'title')}
                        onBlur={() => handleBlur('title')}
                        error={!!errors.title}
                        helperText={errors.title && ''}
                        fullWidth
                        sx={customTextFieldStyles}
                    >
                        <MenuItem value="Mr">Mr</MenuItem>
                        <MenuItem value="Ms">Ms</MenuItem>
                    </TextField>
                    {renderCheckIcon('title')}
                </div>

                {/* First Name */}
                <div className="relative col-span-3">
                    <TextField
                        autoComplete='off'
                        sx={customTextFieldStyles}
                        label="First name (Given name)"
                        value={formData.firstName}
                        onChange={(e) => handleChange(e, 'firstName')}
                        onBlur={() => handleBlur('firstName')}
                        error={!!errors.firstName}
                        helperText={errors.firstName && ''}
                        fullWidth
                    />
                    {renderCheckIcon('firstName')}
                </div>

                {/* Last Name */}
                <div className="relative col-span-3">
                    <TextField
                        autoComplete='off'
                        sx={customTextFieldStyles}
                        label="Last name (Surname)"
                        value={formData.lastName}
                        onChange={(e) => handleChange(e, 'lastName')}
                        onBlur={() => handleBlur('lastName')}
                        error={!!errors.lastName}
                        helperText={errors.lastName && ''}
                        fullWidth
                    />
                    {renderCheckIcon('lastName')}
                </div>

                {/* D.O.B */}
                <div className="relative col-span-2">
                    <TextField
                        label="D.O.B"
                        autoComplete='off'
                        type="date"
                        sx={customTextFieldStyles}
                        InputLabelProps={{ shrink: true }}
                        value={formData.dob}
                        onChange={(e) => handleChange(e, 'dob')}
                        onBlur={() => handleBlur('dob')}
                        error={!!errors.dob}
                        helperText={errors.dob && ''}
                        fullWidth
                        onClick={(e) => e.target.showPicker()} // Ensure calendar opens instantly
                    />

                    <DatePicker label="Basic date picker" />
                    {renderCheckIcon('dob')}
                </div>

                {/* Passport Number */}
                <div className="relative col-span-3">
                    <TextField
                        autoComplete='off'
                        sx={customTextFieldStyles}
                        label="Passport number"
                        value={formData.passportNumber}
                        onChange={(e) => handleChange(e, 'passportNumber')}
                        onBlur={() => handleBlur('passportNumber')}
                        error={!!errors.passportNumber}
                        helperText={errors.passportNumber && ''}
                        fullWidth
                    />
                    {renderCheckIcon('passportNumber')}
                </div>

                {/* Issued Date */}
                <div className="relative col-span-3">
                    <TextField
                        autoComplete='off'
                        sx={customTextFieldStyles}
                        label="Issued date"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        value={formData.issuedDate}
                        onChange={(e) => handleChange(e, 'issuedDate')}
                        onBlur={() => handleBlur('issuedDate')}
                        error={!!errors.issuedDate}
                        helperText={errors.issuedDate && ''}
                        fullWidth
                        onClick={(e) => e.target.showPicker()} // Ensure calendar opens instantly
                    />
                    {renderCheckIcon('issuedDate')}
                </div>

                {/* Expire Date */}
                <div className="relative col-span-3">
                    <TextField
                        autoComplete='off'
                        sx={customTextFieldStyles}
                        label="Expire date"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        value={formData.expireDate}
                        onChange={(e) => handleChange(e, 'expireDate')}
                        onBlur={() => handleBlur('expireDate')}
                        error={!!errors.expireDate}
                        helperText={errors.expireDate && ''}
                        fullWidth
                        onClick={(e) => e.target.showPicker()} // Ensure calendar opens instantly
                    />
                    {renderCheckIcon('expireDate')}
                </div>
            </form>
        </div>
    );
};

export default PassengerForm;

import { Checkbox } from '@mui/material';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setFilterChange } from '../../../../store/homepageFilterSlice';

const DepartureOptions = ({ options, title, checkedItems, handleCheckboxChange }) => {
    return (
        <div className="w-full px-2 text-dark md:w-1/2">
            <h3 className="mb-2 text-[12px] font-semibold">{title}</h3>
            {options.map((option, index) => {
                const { key, label, price } = option;
                return (
                    <div key={index} className="flex items-center justify-between">
                        <div className="flex items-center">
                            <Checkbox
                                checked={!!checkedItems[key]}
                                size="small"
                                id={key + index + price}
                                onChange={() => handleCheckboxChange(key)}
                                sx={{
                                    color: "#10A1D0",
                                    '&.Mui-checked': {
                                        color: "#10A1D0",
                                    },
                                }}
                            />
                            <label
                                htmlFor={key + index + price}
                                className="text-sm font-[550] cursor-pointer select-none md:text-[12px]"
                            >
                                {label}
                            </label>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

const BaggageFilter = () => {
    const [checkedItemsLCY, setCheckedItemsLCY] = useState({});
    const [checkedItemsIND, setCheckedItemsIND] = useState({});
    const [hasChanges, setHasChanges] = useState(false); // Track if any changes occurred

    const lcyOptions = [
        { key: 'OPC', label: '0 PC', price: '400.80' },
        { key: '30', label: '30 KG', price: '£870.60' },
        { key: '2PC', label: '2 PC', price: '£841.89' },
        { key: '30KG', label: '30 KG', price: '£870.60' },
    ];

    const indOptions = [
        { key: 'earlyMorning', label: '0 PC', price: '£870.60' },
        { key: '30', label: '30 KG', price: '£897.60' },
        { key: '2PC', label: '2 PC', price: '£841.89' },
        { key: '30KG', label: '30 KG', price: '£841.89' },
    ];

    // Handle checkbox change for LCY
    const handleCheckboxChangeLCY = (key) => {
        setCheckedItemsLCY((prev) => ({
            ...prev,
            [key]: !prev[key],
        }));
    };

    // Handle checkbox change for IND
    const handleCheckboxChangeIND = (key) => {
        setCheckedItemsIND((prev) => ({
            ...prev,
            [key]: !prev[key],
        }));
    };

    // Clear all selected checkboxes
    const clearAll = () => {
        setCheckedItemsLCY({});
        setCheckedItemsIND({});
    };

    const dispatch = useDispatch()
    useEffect(() => {
        const hasSelectedItemsLCY = Object.values(checkedItemsLCY).some((value) => value);
        const hasSelectedItemsIND = Object.values(checkedItemsIND).some((value) => value);
        setHasChanges(hasSelectedItemsLCY || hasSelectedItemsIND);
        dispatch(setFilterChange({
            name: "baggage",
            data: hasSelectedItemsLCY || hasSelectedItemsIND
        }))
    }, [checkedItemsLCY, checkedItemsIND, dispatch]);

    return (
        <div className="container relative min-w-[250px] mx-auto">
            <h3 className="mb-2 font-semibold text-[13px] text-dark">Select Timing</h3>
            <div className="flex flex-wrap bg-white">
                {/* Departure from LCY */}
                <DepartureOptions
                    options={lcyOptions}
                    title="LCY-IND"
                    checkedItems={checkedItemsLCY}
                    handleCheckboxChange={handleCheckboxChangeLCY}
                />

                {/* Departure from IND */}
                <DepartureOptions
                    options={indOptions}
                    title="IND-LCY"
                    checkedItems={checkedItemsIND}
                    handleCheckboxChange={handleCheckboxChangeIND}
                />
            </div>
            {hasChanges && (
                <motion.div
                    initial={{ opacity: 0, translateY: -15 }}
                    animate={{ opacity: 1, translateY: -15 }}
                    exit={{ opacity: 0, translateY: -15 }}
                    transition={{ duration: 0.3 }}
                    className="absolute top-0 mt-4 mr-4  cursor-pointer text-[13px] text-dark right-2"
                    onClick={clearAll}
                >
                    Clear
                </motion.div>
            )}
        </div>
    );
};

export default BaggageFilter;

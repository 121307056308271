import React from 'react';
import { motion } from 'framer-motion';

const HotDealsPage = () => {
    // Animation variants
    const textVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 },
    };

    return (
        <div className="flex items-center justify-center h-full  min-h-[65vh] bg-primary">
            <div className="text-center text-white">
                <motion.h1
                    className="text-4xl font-bold md:text-5xl"
                    initial="hidden"
                    animate="visible"
                    variants={textVariants}
                    transition={{ duration: 0.5 }}
                >
                    Hot Deals Page
                </motion.h1>
                <motion.p
                    className="mt-4 text-lg md:text-xl"
                    initial="hidden"
                    animate="visible"
                    variants={textVariants}
                    transition={{ duration: 0.5, delay: 0.2 }}
                >
                    Coming Soon
                </motion.p>
            </div>
        </div>
    );
};

export default HotDealsPage;

import { motion } from "framer-motion";
import { useEffect, useState } from "react";

const CountDownTimer = () => {
    const [minutes, setMinutes] = useState(20);
    const [seconds, setSeconds] = useState(50);

    useEffect(() => {
        const timer = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            } else if (minutes > 0) {
                setMinutes(minutes - 1);
                setSeconds(59);
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [minutes, seconds]);

    return (
        <div className="flex flex-col items-center justify-center w-full bg-white font-lato ">
            <div className="mb-4 px-8 bg-[#F7F7F7] py-3 text-center">
                <h2 className="text-xs text-black">Please complete Booking within</h2>
                <div className="flex items-center justify-center space-x-4">
                    {/* Minutes Section */}
                    <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <span className="text-[40px] font-bold text-primary">{minutes}</span>
                        <p className="text-sm text-gray-600">Minutes</p>
                    </motion.div>

                    {/* Gray Separator */}
                    <div className="w-[1px] h-16 bg-primary"></div>  {/* Vertical Line Separator */}

                    {/* Seconds Section */}
                    <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <span className="text-[40px] font-bold text-primary">{String(seconds).padStart(2, '0')}</span>
                        <p className="text-sm text-gray-600">Seconds</p>
                    </motion.div>
                </div>

            </div>

            <div className="text-center ">
                <div className="flex items-center justify-center space-x-2 bg-[#F7F7F7] p-4 rounded-md">
                    <i className="text-2xl text-blue-500 fas fa-headset"></i>
                    <div>
                        <div className="flex items-center gap-2.5">
                            <img
                                src={"/images/logic.svg"}
                                alt={"use"}
                                className="object-contain h-7 w-7 "
                            />
                            <p className="font-semibold text-md">Need Help ?</p>
                        </div>
                        <p className="text-sm font-bold text-primary">
                            Phone: +44 (0)791 632 5248
                        </p>
                        <p className="text-[13px] font-semibold text-primary">(08:00 a.m to 08:00 p.m)</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CountDownTimer;

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckIcon from '@mui/icons-material/Check';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';

const CustomFlightDropdown = ({ options, selectedOption, onOptionSelect }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const dropdownRef = useRef(null);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const getFilteredOptions = () => {
        if(searchTerm){
            return options.filter(option =>
                option.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }else return options;
        
    }

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div ref={dropdownRef} className="relative min-w-28 py-0.5">
            {/* Select button */}
            <button
                onClick={toggleDropdown}
                className="flex whitespace-nowrap text-[13px] items-center justify-between w-full gap-3 font-medium bg-white text-primary"
            >
                {selectedOption}
                <ArrowForwardIosIcon
                    sx={{ fontSize: '14px' }}
                    className={`text-primary transition-transform duration-300 ${isOpen ? '-rotate-90' : 'rotate-90'}`}
                />
            </button>

            {/* Dropdown options */}
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial={{ opacity: 0, y: -20, x: -20 }}
                        animate={{ opacity: 1, y: -7, x: 0 }}
                        exit={{ opacity: 0, y: -10, x: -20 }}
                        transition={{ duration: 0.1 }}
                        className="absolute left-0 z-10 w-full min-w-[250px] py-2 mt-2 bg-white border border-gray-300 rounded-md shadow-lg max-h-[200px] overflow-x-auto custom-scroll"
                    >
                        {/* Search Bar (shows only if more than 5 options) */}
                        {options.length > 5 && (
                            <motion.input
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.3 }}
                                type="text"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="w-full p-2 text-sm text-black bg-white border-0 outline-none focus:ring-0"
                            />
                        )}

                        <ul>
                            {getFilteredOptions().map((option) => (
                                <li
                                    
                                    onClick={() => {
                                        onOptionSelect(option);
                                        setIsOpen(false);
                                    }}
                                    className="flex items-center justify-between p-2 px-5 text-black cursor-pointer whitespace-nowrap hover:bg-gray-100"
                                >
                                    {option}
                                    {selectedOption === option && (
                                        <CheckIcon className="font-bold text-primary" />
                                    )}
                                </li>
                            ))}
                        </ul>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default CustomFlightDropdown;

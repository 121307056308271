import React, { useState, useEffect, useRef } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { motion, AnimatePresence } from 'framer-motion';
import AirlinesFilter from './AirlinesFilter';
import PriceFilter from './PriceFilter';
import StoprFilter from './StoprFilter';
import TimingFilter from './TimingFilter';
import BaggageFilter from './BaggageFilter';
import NearbyAirportFilters from './NearbyAirportFilters';
import ConnectingTimeFilter from './ConnectingTimeFilter';
import { useSelector } from 'react-redux';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';

const FlightScrollTable = () => {
    const [visibleTooltipId, setVisibleTooltipId] = useState(null);
    const storedData = useSelector(state => state?.homePage.filters);
    const tooltipRefs = useRef([]); // Array of tooltip refs
    const buttonRefs = useRef([]); // Array of button refs

    const filterDataOptions = [
        { name: "Price", key: "price", component: <PriceFilter />, icon: "images/logo/filters/price.svg" },
        { name: "Airlines", key: "airlines", component: <AirlinesFilter />, icon: "images/logo/filters/airline.svg" },
        { name: "Timing", key: "timing", component: <TimingFilter />, icon: "images/logo/filters/timing.svg" },
        { name: "Baggage", key: "baggage", component: <BaggageFilter />, icon: "images/logo/filters/baggage.svg" },
        { name: "Nearby Airport", key: "nearbyAirport", component: <NearbyAirportFilters />, icon: "images/logo/filters/nearby.svg" },
        { name: "Connection", key: "connection", component: <ConnectingTimeFilter />, icon: "images/logo/filters/connection.svg" },
        { name: "Stops", key: "stops", component: <StoprFilter />, icon: "images/logo/filters/stop.svg" },
    ];

    const handleTooltipVisibility = (id) => {
        setVisibleTooltipId(visibleTooltipId === id ? null : id);
    };

    // Close tooltip if clicked outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            // Check if clicked outside both the tooltip and the button
            if (
                visibleTooltipId !== null &&
                tooltipRefs.current[visibleTooltipId] &&
                !tooltipRefs.current[visibleTooltipId].contains(event.target) &&
                buttonRefs.current[visibleTooltipId] &&
                !buttonRefs.current[visibleTooltipId].contains(event.target)
            ) {
                setVisibleTooltipId(null);
            }
        };

        if (visibleTooltipId) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [visibleTooltipId]);

    const tooltipVariants = {
        hidden: { opacity: 0, y: -10 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.3 } },
        exit: { opacity: 0, y: -10, transition: { duration: 0.2 } }
    };

    return (
        <div className="grid w-full grid-cols-1 bg-white border border-gray-300 font-lato md:grid-cols-4 xl:grid-cols-8">
            <div className="relative justify-between w-full p-2 font-semibold border-r cursor-pointer whitespace-nowrap md:col-span-1">
                <span className="pl-3 mr-1 text-[12px] font-medium">Filter By</span>
                <span className="pl-3 absolute right-1 h-full top-3 mr-1 text-[12px] text-dark ">
                    <RotateLeftIcon fontSize="small" />
                </span>
            </div>

            {filterDataOptions.map((option, index) => {
                const { name = "", key = "", component, icon = "" } = option;

                return (
                    <div
                        ref={el => buttonRefs.current[key] = el}
                        key={index} className="relative h-full">
                        <button
                            className={`tooltip-button relative flex h-full items-center justify-between w-full px-3 font-medium border-r cursor-pointer whitespace-nowrap md:col-span-1 ${visibleTooltipId === key ? "bg-primary text-white" : ""}`}
                            onClick={() => handleTooltipVisibility(key)}
                        >
                            <div className='flex items-center gap-2 pr-2'>
                                <img
                                    src={icon}
                                    alt={icon}
                                    className="object-contain h-[15px]"
                                />
                                <span className="mr-1 text-[12px]">{name}</span>
                            </div>
                            {/* //from filter count from api */}
                            {storedData[key] && (
                                <div className='absolute flex items-center justify-center flex-shrink-0 w-5 h-5 text-xs font-bold text-white bg-red-600 rounded-full left-2'>
                                    {1}

                                </div>
                            )}
                            <ChevronLeftIcon className='w-4 h-4 -rotate-90' />
                        </button>
                        <AnimatePresence>
                            {visibleTooltipId === key && (
                                <motion.div
                                    ref={el => tooltipRefs.current[key] = el} // Assign ref to each tooltip
                                    initial="hidden"
                                    animate="visible"
                                    exit="exit"
                                    variants={tooltipVariants}
                                    className="absolute top-[40px] left-0 z-50 w-auto p-4 transform bg-white border border-gray-300 rounded-b-lg rounded-tr-lg "
                                    style={{ boxShadow: "0 6px 16px 0 rgba(0, 0, 0, .28)" }}
                                >
                                    {/* Tooltip notch/edge */}
                                    <div className="absolute w-4 h-4 transform rotate-45 -translate-x-1/2 -translate-y-full bg-white border-t border-l border-gray-300 left-2.5 top-2 " />

                                    {/* Tooltip content */}
                                    {component}
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>
                );
            })}
        </div>
    );
};

export default FlightScrollTable;

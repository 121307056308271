import React, { useState } from 'react';
import PassengerForm from './PassengerForm';
import ContactForm from './ContactForm'; // Import ContactForm


const DynamicForm = ({ passengers, setPassengers, setContactErrors, contactErrors, setPassengerErrors, passengerErrors, setIsValidate, isPassanger }) => {
    const validateField = (field, value) => {
        switch (field) {
            case 'title':
                return value !== '';
            case 'firstName':
            case 'lastName':
                return value.trim() !== '';
            case 'dob':
            case 'issuedDate':
            case 'expireDate':
                return value !== '' && new Date(value) <= new Date();
            case 'passportNumber':
                return value.trim().length >= 5;
            case 'email':
                return /\S+@\S+\.\S+/.test(value); // Simple email validation
            case 'countryCode':
                return value.trim() !== '';
            case 'phoneNumber':
                return /^\d{7,15}$/.test(value); // Phone number validation
            default:
                return true;
        }
    };

    const [contactData, setContactData] = useState({
        email: '',
        countryCode: '',
        phoneNumber: ''
    });
    // Handle form field changes for each passenger
    const handlePassengerChange = (index, e, field) => {
        const updatedPassengers = [...passengers];
        updatedPassengers[index][field] = e.target.value;
        setPassengers(updatedPassengers);
    };

    // Handle form field validation on blur for each passenger
    const handlePassengerBlur = (index, field) => {
        const updatedErrors = [...passengerErrors];
        updatedErrors[index] = {
            ...updatedErrors[index],
            [field]: !validateField(field, passengers[index][field]),
        };
        setPassengerErrors(updatedErrors);
    };

    // Handle contact form field changes
    const handleContactChange = (e, field) => {
        setContactData({
            ...contactData,
            [field]: e.target.value
        });
    };

    // Handle contact form field validation on blur
    const handleContactBlur = (field) => {
        setContactErrors({
            ...contactErrors,
            [field]: !validateField(field, contactData[field]),
        });
    };

    return (
        <>
            <div className='pb-3 border border-gray-300 rounded-md shadow-cardShadow'>
                <div className='border-b border-gray-300 font-lato'>
                    <div className='w-full p-2 border-gray-300 '>
                        <span className='py-2 px-1.5  !font-medium text-black text-[15px]'>
                            Travellers Infomation
                        </span>
                    </div>
                </div>

                <div className='flex flex-col gap-6 px-2 pt-3'>
                    {passengers.map((passenger, index) => (
                        <PassengerForm
                            key={index}
                            formData={passenger}
                            title={`Passenger ${index + 1}`}
                            handleChange={(e, field) => handlePassengerChange(index, e, field)}
                            handleBlur={(field) => handlePassengerBlur(index, field)}
                            errors={passengerErrors[index]}
                        />
                    ))}
                </div>
            </div>
            <div className='pb-3 border border-gray-300 rounded-md shadow-cardShadow'>
                <div className='border-b border-gray-300 font-lato'>
                    <div className='w-full p-2 border-gray-300 '>
                        <span className='py-2 px-1.5  !font-medium text-black text-[15px]'>
                            Contact Information
                        </span>
                    </div>
                </div>

                {/* Add the ContactForm component below the passengers */}
                <div className='flex flex-col gap-6 px-2 pt-3'>
                    <ContactForm
                        formData={contactData}
                        handleChange={handleContactChange}
                        handleBlur={handleContactBlur}
                        errors={contactErrors}
                    />
                </div>
            </div>


            <div className="flex justify-between w-full space-x-4">
                {/* Change Flight Button */}
                <button className="flex items-center px-4 text-[15px] py-2 text-white rounded-md bg-primary">
                    <img
                        src="images/payment/enter.svg" // Placeholder image for the icon
                        alt="icon"
                        className="mr-2"
                    />
                    <span>Change Flight</span>
                </button>

                {/* Continue to Pay Button */}
                <button
                    disabled={!isPassanger}
                    onClick={() => { setIsValidate(true) }}
                    className={`flex items-center text-[15px] justify-between px-4 py-2 text-white rounded-md  disabled:bg-gray-500
                     ${isPassanger ? "bg-primary" : " bg-gray-300 cursor-not-allowed"}`}>
                    <span className="flex items-center">
                        Continue to pay
                        {isPassanger && <img
                            src="images/payment/buttons.svg" // Placeholder image for the icon
                            alt="icon"
                            className="ml-2"
                        />}
                    </span>
                    <span className="ml-4">£1,100.00</span>
                </button>
            </div>
        </>
    );
};

export default DynamicForm;

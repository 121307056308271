import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Footer from './components/layout/Footer';
import Header from './components/layout/Header';
import FlightFilterPage from './components/layout/home/flightFilter';
import FlightHomePage from './components/layout/home/Flights';
import ContactPage from './pages/ContactPage';
import HotDealsPage from './pages/HotDealsPage';
import FlightPaymentPage from './components/layout/home/payment';

const HomePage = () => {
  return (
    <Router>
      <div className="flex flex-col min-h-screen font-lato">
        <Header />
        <main className="flex-grow h-full">
          <Routes>
            <Route path="/" element={<FlightHomePage />} />
            <Route path="/hot-deals" element={<HotDealsPage />} />
            <Route path="/contact-us" element={<ContactPage />} />
            <Route path="/flight-filter" element={<FlightFilterPage />} />
            <Route path="/flight-payment" element={<FlightPaymentPage />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default HomePage;

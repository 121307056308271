import { useState } from 'react';
import { motion } from 'framer-motion';
import SearchedFlightCard from '../../../common/SearchedFlightCard';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const FlightCardGrid = () => {
    const cardData = [
        { imageUrl: "mumbai", title: "Mumbai", price: "£600 pp" },
        { imageUrl: "delhi", title: "Delhi", price: "£500 pp" },
        { imageUrl: "kolkata", title: "Kolkata", price: "£400 pp" },
        { imageUrl: "chennai", title: "Chennai", price: "£450 pp" },
        { imageUrl: "mumbai", title: "Mumbai", price: "£600 pp" },
        { imageUrl: "mumbai", title: "Mumbai", price: "£600 pp" },
        { imageUrl: "chennai", title: "Chennai", price: "£450 pp" },
        { imageUrl: "chennai", title: "Chennai", price: "£450 pp" },


    ];

    const [showAll, setShowAll] = useState(false);

    // Function to toggle the visibility of all cards
    const toggleShowAll = () => {
        setShowAll((prev) => !prev);
    };

    // Get the number of cards to display
    const displayedCards = showAll ? cardData : cardData.slice(0, 12);

    return (
        <div className="grid w-full grid-cols-1 gap-4  sm:grid-cols-2 lg:grid-cols-4 max-w-[1312px]">
            {displayedCards.map((card, index) => (
                <SearchedFlightCard
                    key={index}
                    imageUrl={card.imageUrl}
                    title={card.title}
                    price={card.price}
                />
            ))}
            {cardData.length > 12 && (
                <div className="flex flex-col items-center justify-center w-full mt-4 text-primary col-span-full">
                    <motion.div
                        className="flex items-center cursor-pointer"
                        onClick={toggleShowAll}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                    >
                        {showAll ? (
                            <>
                                <motion.span
                                    className="mr-2"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    Show Less
                                </motion.span>
                                <motion.div
                                    className='flex items-center mt-3'
                                    initial={{ rotate: 0 }}
                                    animate={{ rotate: 90 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <ArrowBackIosIcon className='animate-pulse' />
                                </motion.div>
                            </>
                        ) : (
                            <>
                                <motion.span
                                    className="mr-2"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    Show More
                                </motion.span>
                                <motion.div
                                    className='flex items-center mb-3'
                                    initial={{ rotate: 0 }}
                                    animate={{ rotate: -90 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <ArrowBackIosIcon className=' animate-pulse' />
                                </motion.div>
                            </>
                        )}
                    </motion.div>
                </div>
            )}
        </div>
    );
};

export default FlightCardGrid;

import React from "react";
import { motion } from "framer-motion"

const SectionHeader = ({ title, subtitle }) => {
    return (
        <div className="text-center">
            {/* Title */}
            <motion.h1
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1.5 }}
                className="text-2xl font-bold  text-gray-900 md:text-3xl lg:text-[36px]">
                {title}
            </motion.h1>

            {/* Subtitle */}
            <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1.5 }}
                className="mt-4 text-sm text-gray-500 md:text-base lg:text-[20px]">
                {subtitle}
            </motion.p>
        </div>
    );
};

export default SectionHeader;

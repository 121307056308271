// src/store/store.js
import { configureStore } from '@reduxjs/toolkit';
import homePageSlice from './homepageFilterSlice';

const store = configureStore({
    reducer: {
        homePage: homePageSlice,
    },
});

export default store;

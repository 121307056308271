import { Checkbox } from '@mui/material';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setFilterChange } from '../../../../store/homepageFilterSlice';

const DepartureOptions = ({ options, title, checkedItems, handleCheckboxChange }) => {
    return (
        <div className="w-full px-2 mb-4 text-dark md:w-1/2">
            <h3 className="mb-2 text-dark text-[13px] font-semibold">{title}</h3>
            {options.map((option, index) => {
                const { key, label, price } = option;
                return (
                    <div key={index} className="flex items-center justify-between">
                        <div className="flex items-center">
                            <Checkbox
                                checked={!!checkedItems[key]}
                                id={key + index + price}
                                size="small"
                                onChange={() => handleCheckboxChange(key)}
                                sx={{
                                    color: "#10A1D0",
                                    '&.Mui-checked': {
                                        color: "#10A1D0",
                                    },
                                }}
                            />
                            <label
                                htmlFor={key + index + price}
                                className="text-[10px] font-[550] cursor-pointer select-none md:text-[12px]"
                            >
                                {label}
                            </label>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

const TimingFilter = () => {
    const [checkedItemsLCY, setCheckedItemsLCY] = useState({});
    const [checkedItemsIND, setCheckedItemsIND] = useState({});
    const [hasChanges, setHasChanges] = useState(false); // Track if any changes occurred

    const lcyOptions = [
        { key: 'earlyMorning', label: 'Early Morning(00:00 - 07:00)', price: '£882.40' },
        { key: 'morning', label: 'Morning(07:00 - 10:00)', price: '£870.60' },
        { key: 'lateMorning', label: 'Late Morning(10:00 - 14:00)', price: '£841.89' },
        { key: 'afternoon', label: 'Afternoon(14:00 - 18:00)', price: '£870.60' },
        { key: 'evening', label: 'Evening(18:00 - 22:00)', price: '£925.40' },
    ];

    const indOptions = [
        { key: 'earlyMorning', label: 'Early Morning(00:00 - 07:00)', price: '£870.60' },
        { key: 'morning', label: 'Morning(07:00 - 10:00)', price: '£897.60' },
        { key: 'lateMorning', label: 'Late Morning(10:00 - 14:00)', price: '£841.89' },
        { key: 'afternoon', label: 'Afternoon(14:00 - 18:00)', price: '£841.89' },
        { key: 'evening', label: 'Evening(18:00 - 22:00)', price: '£841.89' },
    ];

    // Handle checkbox change for LCY
    const handleCheckboxChangeLCY = (key) => {
        setCheckedItemsLCY((prev) => ({
            ...prev,
            [key]: !prev[key],
        }));
    };

    // Handle checkbox change for IND
    const handleCheckboxChangeIND = (key) => {
        setCheckedItemsIND((prev) => ({
            ...prev,
            [key]: !prev[key],
        }));
    };

    // Clear all selected checkboxes
    const clearAll = () => {
        setCheckedItemsLCY({});
        setCheckedItemsIND({});
    };

    // Check if there are any selected checkboxes
    const dispatch = useDispatch()
    useEffect(() => {
        const hasSelectedItemsLCY = Object.values(checkedItemsLCY).some((value) => value);
        const hasSelectedItemsIND = Object.values(checkedItemsIND).some((value) => value);
        setHasChanges(hasSelectedItemsLCY || hasSelectedItemsIND);
        dispatch(setFilterChange({
            name: "timing",
            data: hasSelectedItemsLCY || hasSelectedItemsIND
        }))
    }, [checkedItemsLCY, checkedItemsIND, dispatch]);

    return (
        <div className="container relative w-full p-1 mx-auto text-dark min-w-[500px]">
            <h3 className="text-dark text-[14px] pb-1">Select Timing</h3>
            <div className="flex flex-wrap bg-white">
                {/* Departure from LCY */}
                <DepartureOptions
                    options={lcyOptions}
                    title="Depart From LCY"
                    checkedItems={checkedItemsLCY}
                    handleCheckboxChange={handleCheckboxChangeLCY}
                />

                {/* Departure from IND */}
                <DepartureOptions
                    options={indOptions}
                    title="Depart From IND"
                    checkedItems={checkedItemsIND}
                    handleCheckboxChange={handleCheckboxChangeIND}
                />
            </div>
            {hasChanges && (
                <motion.div
                    initial={{ opacity: 0, translateY: -15 }}
                    animate={{ opacity: 1, translateY: -15 }}
                    exit={{ opacity: 0, translateY: -15 }}
                    transition={{ duration: 0.3 }}
                    className="absolute mt-1 mr-4 text-sm cursor-pointer text-dark right-2 top-2"
                    onClick={clearAll}
                >
                    Clear
                </motion.div>
            )}
        </div>
    );
};

export default TimingFilter;

import axios from 'axios';
import { BASE_URL } from '../assets/constant';

const USERNAME = '11202776';
const PASSWORD = '60-dayfreetrial';

export const fetchAirlineConfig = async (configType) => {
    try {
        const response = await axios.get(`${BASE_URL}api/v1/air/configuration?configType=${configType}`
        );
        return response.data;
    } catch (error) {
        console.error("Error fetching airline configuration data:", error);
        throw error; // rethrow the error so it can be handled where this function is called
    }
};

// src/store/exampleSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedOptions: {
        roundTrip: 'Round Trip',
        allAirlines: 'All Airlines',
        anyClass: 'Any Class',
    },
    filters: {},
    passengers: [
        {
            title: '',
            firstName: '',
            lastName: '',
            dob: '',
            passportNumber: '',
            issuedDate: '',
            expireDate: '',
            passangeType: 'Adult'
        },
        {
            title: '',
            firstName: '',
            lastName: '',
            dob: '',
            passportNumber: '',
            issuedDate: '',
            expireDate: '',
            passangeType: 'child'
        }
    ]
};

const homepageFilterSlice = createSlice({
    name: 'homepage',
    initialState,
    reducers: {
        setHomePageSelectedOptions: (state, action) => {
            state.selectedOptions = action.payload;
        },
        setFilterChange: (state, action) => {
            state.filters[action.payload.name] = action.payload.data;
        },
        setPassangerData: (state, action) => {
            state.passengers = action.payload;
        },

    },
});

export const { setFilterChange, setPassangerData, setHomePageSelectedOptions } = homepageFilterSlice.actions;

export default homepageFilterSlice.reducer;

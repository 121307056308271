
import React, { useState } from 'react'
import StepIndicator from '../../../common/StepIndicator'
import RightSideSection from './RightSideSection'
import PaymentConfirmation from './left/PaymentConfirmation'
import PaymentOptions from './left/PaymentOptions'
import DynamicForm from './left/DynamicForm'
import PaymentFinalCard from './left/PaymentFinalCard'


const FlightPaymentPage = () => {
    const [passengers, setPassengers] = useState([
        {
            title: '',
            firstName: '',
            lastName: '',
            dob: '',
            passportNumber: '',
            issuedDate: '',
            expireDate: '',
            passangeType: 'Adult'
        },
        {
            title: '',
            firstName: '',
            lastName: '',
            dob: '',
            passportNumber: '',
            issuedDate: '',
            expireDate: '',
            passangeType: 'child'
        }
    ]);
    const [isValidate, setIsValidate] = useState(false)
    const [passengerErrors, setPassengerErrors] = useState([
        {

        },
        {

        }
    ]);
    const [contactErrors, setContactErrors] = useState({

    });

    const isContact = Object?.values(contactErrors)?.length > 0 && Object?.values(contactErrors)?.every(item => item === false)
    function checkArray(arr) {
        return arr.every(obj => {
            // Check if the object is not empty
            const hasEntries = Object.keys(obj).length > 0;

            // Check if all values are false
            const allFalse = Object.values(obj).every(value => value === false);

            return hasEntries && allFalse;
        });
    }

    const isPassanger = checkArray(passengerErrors) && isContact

    return (
        <div className='flex flex-col pb-5 items-center max-w-[1312px] justify-center w-full gap-2 mx-auto'>
            <StepIndicator currentStep={3} />
            <div className='flex justify-between w-full gap-4'>
                <div className='flex flex-col w-full gap-3'>
                    <PaymentFinalCard />
                    <DynamicForm
                        setIsValidate={setIsValidate}
                        isPassanger={isPassanger}
                        passengerErrors={passengerErrors}
                        setPassengerErrors={setPassengerErrors}
                        contactErrors={contactErrors}
                        setContactErrors={setContactErrors}
                        passengers={passengers} setPassengers={setPassengers} />
                    {(isValidate && isPassanger) && <div>
                        <PaymentOptions />
                        <PaymentConfirmation />
                    </div>}
                </div>
                <div className='w-full max-w-[400px]'>
                    <RightSideSection />
                </div>
            </div>
        </div>
    )
}

export default FlightPaymentPage
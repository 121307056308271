import { motion } from "framer-motion";

const PriceBreakdown = ({ isType2 = false }) => {
    return (
        <div className=" min-w-[200px] mx-auto text-dark ">
            {/* Adults Row */}
            <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="flex items-center justify-between pb-2 mb-2"
            >
                <div>
                    <p className="text-sm font-medium">Adults x 1</p>
                    <p className="text-sm text-primary">Price per adult</p>
                </div>
                <div className="text-right">
                    <p className="font-bold text-balancetext-black ">£400.00</p>
                    <p className="text-sm text-primary">£400.00</p>
                </div>
            </motion.div>

            {/* Child Row */}
            <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                className="flex items-center justify-between pb-2 mb-2 "
            >
                <div>
                    <p className="text-sm font-medium">Child x 2</p>
                    <p className="text-sm text-primary">Price per child</p>
                </div>
                <div className="text-right">
                    <p className="font-bold">£600.00</p>
                    <p className="text-sm text-primary">£300.00</p>
                </div>
            </motion.div>

            {/* Infant Row */}
            <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.4 }}
                className="flex items-center justify-between pb-2 mb-2 border-b"
            >
                <div>
                    <p className="text-sm font-medium">Infant x 2</p>
                    <p className="text-[10px] text-primary">Price per infant</p>
                </div>
                <div className="text-right">
                    <p className="font-bold">£100.00</p>
                    <p className="text-sm text-primary">£200.00</p>
                </div>
            </motion.div>

            {/* Total Amount */}
            {isType2 ?
                <motion.div
                    initial={{ opacity: 0, y: 0 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.6 }}
                    className="flex items-center justify-between "
                >
                    <p className="text-[15px] font-semibold">Total amount</p>
                    <p className="text-[16px] font-extrabold">£1100.00</p>
                </motion.div> : <motion.div
                    initial={{ opacity: 0, y: 0 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.6 }}
                    className="flex items-center justify-between "
                >
                    <p className="text-base font-bold">Total amount</p>
                    <p className="text-base font-bold">£1,100.00</p>
                </motion.div>}
        </div>
    );
};

export default PriceBreakdown;

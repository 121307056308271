import { Checkbox } from '@mui/material';
import React, { useState } from 'react';

const CheckboxList = ({ options }) => {
    const [checkedItems, setCheckedItems] = useState({});

    const handleCheckboxChange = (key) => {
        setCheckedItems((prev) => ({
            ...prev,
            [key]: !prev[key],
        }));
    };

    return (
        <div className="">
            {options.map((option, index) => (
                <div key={index} className="flex items-center">
                    <Checkbox
                        id={option.key}
                        size="small"
                        checked={!!checkedItems[option.key]}
                        onChange={() => handleCheckboxChange(option.key)}
                        sx={{
                            color: "#10A1D0",
                            '&.Mui-checked': {
                                color: "#10A1D0",
                            },
                        }}
                    />
                    <label htmlFor={option.key} className="font-[550] text-[12px] text-black cursor-pointer">
                        {option.name}
                    </label>
                </div>
            ))}
        </div>
    );
};

export default CheckboxList;

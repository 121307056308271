import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import React from 'react';
import StopInfo from '../../flightFilter/StopInfo';
const FlightFinalCard = ({ isBorder = false }) => {
    return (
        <div className={`flex-1   ${isBorder ? "border-r border-gray-300" : ""}`}>
            <div className="flex items-start justify-between">
                <div className='flex items-end gap-1'>
                    <h3 className="font-semibold">London to Mumbai On</h3>
                    <span className="text-sm text-gray-500">Sunday, 22 September, 2024</span>
                </div>
            </div>

            <div className="grid grid-cols-4 mt-4 space-x-4">
                <div className='flex flex-col col-span-1'>
                    <div className='flex items-center gap-2'>
                        <img
                            src="/images/logo/filters/ai.svg" // Airline logo placeholder
                            alt="Airline logo"
                            className="w-[23px] h-[23px]"
                        />
                        <p className='text-[13px] text-[#575756]'>Air India</p>
                    </div>
                    <div className="relative flex flex-col items-center justify-center h-20">
                        {/* Top vertical line */}
                        <div className="w-px h-6 bg-[#585555]"></div>

                        {/* Airplane Icon */}
                        <div className="absolute w-6 h-6 top-6.5 flex items-center py-4 bg-[#f7f7f7]">
                            <AirplanemodeActiveIcon className='rotate-180 text-[#585555]' />
                        </div>

                        {/* Bottom vertical line */}
                        <div className="w-px h-12 bg-[#585555]"></div>
                    </div>
                    <div className='flex items-center gap-2'>
                        <img
                            src="/images/logo/filters/ai.svg" // Airline logo placeholder
                            alt="Airline logo"
                            className="w-[23px] h-[23px]"
                        />
                        <p className='text-[13px] text-[#575756]'>Air India</p>
                    </div>
                </div>
                <div className='flex flex-col col-span-2 gap-2'>
                    <div>
                        <div className='flex items-end gap-2'>
                            <div className="text-[15px] font-bold">16:25</div>
                            <div className="text-[13px] text-gray-500">Sun, 22 Sep</div>
                        </div>
                        <div className="text-[13px] text-gray-500">LHR - London Heathrow</div>
                    </div>
                    <div className='border-b max-w-[70%] border-gray-300'>
                        <StopInfo type="payment" />
                    </div>
                    <div>
                        <div className='flex items-end gap-2'>
                            <div className="text-[15px] font-bold">16:25</div>
                            <div className="text-[13px] text-gray-500">Sun, 22 Sep</div>
                        </div>
                        <div className="text-[13px] text-gray-500">LHR - London Heathrow</div>
                    </div>
                </div>
                <div className='flex flex-col justify-between'>
                    <div className="flex items-center space-x-2">
                        <img src="/images/logo/filters/bag.svg" alt="Baggage icon" className="object-contain w-4 h-6" />
                        <span className="text-xs font-medium text-[#575756]">2 PC</span>
                    </div>
                    <div className="flex items-center space-x-2">
                        <img src="/images/logo/filters/bag.svg" alt="Baggage icon" className="object-contain w-4 h-6" />
                        <span className="text-xs font-medium text-[#575756]">2 PC</span>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default FlightFinalCard
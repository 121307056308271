import CheckIcon from '@mui/icons-material/Check';
import { Radio } from '@mui/material';
import { motion } from 'framer-motion';
import React, { useState } from 'react';

const PaymentOptions = () => {
    const [selectedOption, setSelectedOption] = useState("bank");

    return (
        <div className="w-full mx-auto">
            {/* Payment Options */}
            <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="px-4 pt-4 pb-2 bg-gray-100 border border-gray-300 rounded-lg shadow-cardShadow"
            >
                <h3 className="mb-2 pb-0.5 inline-block border-b-[1.5px] border-[#0B1A9D] text-sm font-medium">Payment Options</h3>

                {/* Pay with Bank App */}
                <div
                    className={`flex pt-2 border-b border-gray-300 gap-2 items-center cursor-pointer  mb-2`}
                    onClick={() => setSelectedOption('bank')}
                >

                    <Radio
                        checked={selectedOption === 'bank'}
                        size="small"
                        onChange={() => setSelectedOption('bank')}
                        sx={{
                            color: "#10A1D0",
                            '&.Mui-checked': {
                                color: "#10A1D0",
                            },
                        }}
                    />
                    <div className="flex-1">
                        <p className="text-xs font-medium">
                            Pay with Bank Application by Scanning QR Code and earn a
                            <span className="text-red-500"> £15.00 Cash Reward</span>
                        </p>
                    </div>
                    <img src="/images/payment/super.svg" alt="Super" className="w-16" />
                </div>
                {/* Google Pay */}
                <div
                    className={`flex  gap-2 items-center cursor-pointer  border-b border-gray-300  mb-2`}
                    onClick={() => setSelectedOption('google')}
                >

                    <Radio
                        checked={selectedOption === 'google'}
                        size="small"
                        onChange={() => setSelectedOption('google')}
                        sx={{
                            color: "#10A1D0",
                            '&.Mui-checked': {
                                color: "#10A1D0",
                            },
                        }}
                    />
                    <div className="flex-1">
                        <p className="text-xs font-medium">
                            Google Pay
                        </p>
                    </div>
                    <img src="/images/payment/gpay.svg" alt="Super" className="w-16" />
                </div>
                <div
                    className={`flex  gap-2 items-center cursor-pointer`}
                    onClick={() => setSelectedOption('card')}
                >

                    <Radio
                        checked={selectedOption === 'card'}
                        size="small"
                        onChange={() => setSelectedOption('card')}
                        sx={{
                            color: "#10A1D0",
                            '&.Mui-checked': {
                                color: "#10A1D0",
                            },
                        }}
                    />
                    <div className="flex-1">
                        <p className="text-xs font-medium">
                            Debit/Credit Card
                        </p>
                    </div>
                    <div className='flex items-center gap-2'>
                        <img src="/images/payment/visa.svg" alt="Super" className="w-[20px] h-[20px]" />
                        <img src="/images/payment/master.svg" alt="Super" className="w-[20px] h-[20px]" />
                    </div>
                </div>

            </motion.div>

            {/* Payment Explanation */}
            {selectedOption === 'bank' && <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="p-4 mt-2 bg-gray-100 border border-gray-300 rounded-lg shadow-cardShadow"
            >
                <ul className="space-y-2">
                    <li className="flex items-start gap-2">
                        <CheckIcon fontSize="small" className='text-primary' />
                        <p className='text-xs'>
                            <strong>What's a Cash Reward?</strong> Using Super to pay, you'll earn a Cash Reward, which is automatically applied as money off the next time you shop.
                        </p>
                    </li>
                    <li className="flex items-start gap-2">
                        <CheckIcon fontSize="small" className='text-primary' />
                        <p className='text-xs'>
                            <strong>Fast:</strong> Confirm via your bank app in just seconds – no card required.
                        </p>
                    </li>
                    <li className="flex items-start gap-2">
                        <CheckIcon fontSize="small" className='text-primary' />
                        <p className='text-xs'>
                            <strong>Safe:</strong> Simply scan the QR code and confirm payment with Face ID or fingerprint verification.
                        </p>
                    </li>
                    <li className="flex items-start gap-2 pt-1">
                        <div className='w-[25px]'>
                        </div>
                        <p className='text-xs'>
                            <img src="/images/payment/payments.svg" alt="Super" />
                        </p>
                    </li>
                    <li className="flex items-start gap-2 pt-1">
                        <div className='w-[25px]'>
                        </div>

                        <p className="text-xs text-black">+ many more major banks</p>
                    </li>

                </ul>

            </motion.div>}
        </div >
    );
};

export default PaymentOptions;

export const countries = [
    { code: 'AD', label: 'AD', phone: '376' },
    { code: 'AI', label: 'AI', phone: '1-264' },
    { code: 'AL', label: 'AL', phone: '355' },
    { code: 'AM', label: 'AM', phone: '374' },
    { code: 'AO', label: 'AO', phone: '244' },
    { code: 'AQ', label: 'AQ', phone: '672' },
    { code: 'AR', label: 'AR', phone: '54' },
    { code: 'AS', label: 'AS', phone: '1-684' },
    { code: 'AT', label: 'AT', phone: '43' },
    { code: 'AW', label: 'AW', phone: '297' },
    { code: 'AX', label: 'AX', phone: '358' },
    { code: 'AZ', label: 'AZ', phone: '994' },
    { code: 'BB', label: 'BB', phone: '1-246' },
    { code: 'BD', label: 'BD', phone: '880' },
    { code: 'BE', label: 'BE', phone: '32' },
    { code: 'BF', label: 'BF', phone: '226' },
    { code: 'BG', label: 'BG', phone: '359' },
    { code: 'BH', label: 'BH', phone: '973' },
    { code: 'BI', label: 'BI', phone: '257' },
    { code: 'BJ', label: 'BJ', phone: '229' },
    { code: 'BL', label: 'BL', phone: '590' },
    { code: 'BM', label: 'BM', phone: '1-441' },
    { code: 'BN', label: 'BN', phone: '673' },
    { code: 'BO', label: 'BO', phone: '591' },
    { code: 'BR', label: 'BR', phone: '55' },
    { code: 'BS', label: 'BS', phone: '1-242' },
    { code: 'BT', label: 'BT', phone: '975' },
    { code: 'BV', label: 'BV', phone: '47' },
    { code: 'BW', label: 'BW', phone: '267' },
    { code: 'BY', label: 'BY', phone: '375' },
    { code: 'BZ', label: 'BZ', phone: '501' },
    { code: 'CH', label: 'CH', phone: '41' },
    { code: 'CI', label: 'CI', phone: '225' },
    { code: 'CK', label: 'CK', phone: '682' },
    { code: 'CL', label: 'CL', phone: '56' },
    { code: 'CM', label: 'CM', phone: '237' },
    { code: 'CN', label: 'CN', phone: '86' },
    { code: 'CO', label: 'CO', phone: '57' },
    { code: 'CR', label: 'CR', phone: '506' },
    { code: 'CU', label: 'CU', phone: '53' },
    { code: 'CV', label: 'CV', phone: '238' },
    { code: 'CW', label: 'CW', phone: '599' },
    { code: 'CX', label: 'CX', phone: '61' },
    { code: 'CY', label: 'CY', phone: '357' },
    { code: 'CZ', label: 'CZ', phone: '420' },
    { code: 'DJ', label: 'DJ', phone: '253' },
    { code: 'DK', label: 'DK', phone: '45' },
    { code: 'DM', label: 'DM', phone: '1-767' },
    { code: 'DZ', label: 'DZ', phone: '213' },
    { code: 'EC', label: 'EC', phone: '593' },
    { code: 'EE', label: 'EE', phone: '372' },
    { code: 'EG', label: 'EG', phone: '20' },
    { code: 'EH', label: 'EH', phone: '212' },
    { code: 'ER', label: 'ER', phone: '291' },
    { code: 'ES', label: 'ES', phone: '34' },
    { code: 'ET', label: 'ET', phone: '251' },
    { code: 'FI', label: 'FI', phone: '358' },
    { code: 'FJ', label: 'FJ', phone: '679' },
    { code: 'GA', label: 'GA', phone: '241' },
    { code: 'GB', label: 'GB', phone: '44' },
    { code: 'GD', label: 'GD', phone: '1-473' },
    { code: 'GE', label: 'GE', phone: '995' },
    { code: 'GF', label: 'GF', phone: '594' },
    { code: 'GG', label: 'GG', phone: '44' },
    { code: 'GH', label: 'GH', phone: '233' },
    { code: 'GI', label: 'GI', phone: '350' },
    { code: 'GL', label: 'GL', phone: '299' },
    { code: 'GM', label: 'GM', phone: '220' },
    { code: 'GN', label: 'GN', phone: '224' },
    { code: 'GP', label: 'GP', phone: '590' },
    { code: 'GQ', label: 'GQ', phone: '240' },
    { code: 'GR', label: 'GR', phone: '30' },
    { code: 'GT', label: 'GT', phone: '502' },
    { code: 'GU', label: 'GU', phone: '1-671' },
    {
        code: 'GW', label: 'GW', phone: '245'
    },
    { code: 'GY', label: 'GY', phone: '592' },
    { code: 'HK', label: 'HK', phone: '852' },
    { code: 'HN', label: 'HN', phone: '504' },
    { code: 'HR', label: 'HR', phone: '385' },
    { code: 'HT', label: 'HT', phone: '509' },
    { code: 'HU', label: 'HU', phone: '36' },
    { code: 'ID', label: 'ID', phone: '62' },
    { code: 'IE', label: 'IE', phone: '353' },
    { code: 'IL', label: 'IL', phone: '972' },
    {
        code: 'IM', label: 'IM', phone: '44'
    },
    { code: 'IN', label: 'IN', phone: '91' },
    { code: 'IS', label: 'IS', phone: '354' },
    { code: 'IT', label: 'IT', phone: '39' },
    { code: 'JE', label: 'JE', phone: '44' },
    { code: 'JM', label: 'JM', phone: '1-876' },
    { code: 'JO', label: 'JO', phone: '962' },
    { code: 'KE', label: 'KE', phone: '254' },
    { code: 'KG', label: 'KG', phone: '996' },
    { code: 'KH', label: 'KH', phone: '855' },
    { code: 'KI', label: 'KI', phone: '686' },
    { code: 'KM', label: 'KM', phone: '269' },
    {
        code: 'KR', label: 'KR', phone: '82'
    },
    { code: 'KW', label: 'KW', phone: '965' },
    {
        code: 'KY', label: 'KY', phone: '1 - 345'
    },
    { code: 'KZ', label: 'KZ', phone: '7' },
    { code: 'LB', label: 'LB', phone: '961' },
    {
        code: 'LC', label: 'LC', phone: '1 - 758'
    },
    { code: 'LI', label: 'LI', phone: '423' },
    {
        code: 'LK', label: 'LK', phone: '94'
    },
    { code: 'LR', label: 'LR', phone: '231' },
    { code: 'LS', label: 'LS', phone: '266' },
    { code: 'LT', label: 'LT', phone: '370' },
    { code: 'LU', label: 'LU', phone: '352' },
    { code: 'LV', label: 'LV', phone: '371' },
    { code: 'LY', label: 'LY', phone: '218' },
    { code: 'MA', label: 'MA', phone: '212' },
    { code: 'MC', label: 'MC', phone: '377' },
    { code: 'ME', label: 'ME', phone: '382' },
    { code: 'MG', label: 'MG', phone: '261' },
    { code: 'MN', label: 'MN', phone: '976' },
    { code: 'MO', label: 'MO', phone: '853' },
    { code: 'MQ', label: 'MQ', phone: '596' },
    { code: 'MR', label: 'MR', phone: '222' },
    { code: 'MS', label: 'MS', phone: '1-664' },
    { code: 'MT', label: 'MT', phone: '356' },
    { code: 'MU', label: 'MU', phone: '230' },
    { code: 'MV', label: 'MV', phone: '960' },
    { code: 'MW', label: 'MW', phone: '265' },
    { code: 'MX', label: 'MX', phone: '52' },
    { code: 'MY', label: 'MY', phone: '60' },
    { code: 'MZ', label: 'MZ', phone: '258' },
    { code: 'NA', label: 'NA', phone: '264' },
    { code: 'NL', label: 'NL', phone: '31' },
    { code: 'NO', label: 'NO', phone: '47' },
    { code: 'NP', label: 'NP', phone: '977' },
    { code: 'NR', label: 'NR', phone: '674' },
    { code: 'NU', label: 'NU', phone: '683' },
    { code: 'PE', label: 'PE', phone: '51' },
    { code: 'PL', label: 'PL', phone: '48' },
    { code: 'PN', label: 'PN', phone: '870' },
    { code: 'PY', label: 'PY', phone: '595' },
    { code: 'QA', label: 'QA', phone: '974' },
    { code: 'RE', label: 'RE', phone: '262' },
    { code: 'RO', label: 'RO', phone: '40' },
    { code: 'RS', label: 'RS', phone: '381' },
    { code: 'SE', label: 'SE', phone: '46' },
    { code: 'SG', label: 'SG', phone: '65' },
    { code: 'SR', label: 'SR', phone: '597' },
    { code: 'TG', label: 'TG', phone: '228' },
    { code: 'TH', label: 'TH', phone: '66' },
    { code: 'TJ', label: 'TJ', phone: '992' },
    { code: 'TK', label: 'TK', phone: '690' },
    { code: 'TL', label: 'TL', phone: '670' },
    { code: 'TM', label: 'TM', phone: '993' },
    { code: 'TN', label: 'TN', phone: '216' },
    { code: 'TO', label: 'TO', phone: '676' },
    { code: 'TR', label: 'TR', phone: '90' },
    { code: 'TV', label: 'TV', phone: '688' },
    { code: 'UA', label: 'UA', phone: '380' },
    { code: 'UG', label: 'UG', phone: '256' },
    { code: 'UY', label: 'UY', phone: '598' },
    { code: 'UZ', label: 'UZ', phone: '998' },
    { code: 'VE', label: 'VE', phone: '58' },
    { code: 'VN', label: 'VN', phone: '84' },
    { code: 'VU', label: 'VU', phone: '678' },
    { code: 'YE', label: 'YE', phone: '967' },
    { code: 'YT', label: 'YT', phone: '262' },
    { code: 'ZA', label: 'ZA', phone: '27' },
    { code: 'ZM', label: 'ZM', phone: '260' },
    { code: 'ZW', label: 'ZW', phone: '263' },
];

export const countryCodes = {
    "AFGHANISTAN": "AF",
    "ALBANIA": "AL",
    "ALGERIA": "DZ",
    "ANDORRA": "AD",
    "ANGOLA": "AO",
    "ANTIGUA AND BARBUDA": "AG",
    "ARGENTINA": "AR",
    "ARMENIA": "AM",
    "AUSTRALIA": "AU",
    "AUSTRIA": "AT",
    "AZERBAIJAN": "AZ",
    "BAHAMAS": "BS",
    "BAHRAIN": "BH",
    "BANGLADESH": "BD",
    "BARBADOS": "BB",
    "BELARUS": "BY",
    "BELGIUM": "BE",
    "BELIZE": "BZ",
    "BENIN": "BJ",
    "BHUTAN": "BT",
    "BOLIVIA": "BO",
    "BOSNIA AND HERZEGOVINA": "BA",
    "BOTSWANA": "BW",
    "BRAZIL": "BR",
    "BRUNEI": "BN",
    "BULGARIA": "BG",
    "BURKINA FASO": "BF",
    "BURUNDI": "BI",
    "CABO VERDE": "CV",
    "CAMBODIA": "KH",
    "CAMEROON": "CM",
    "CANADA": "CA",
    "CENTRAL AFRICAN REPUBLIC": "CF",
    "CHAD": "TD",
    "CHILE": "CL",
    "CHINA": "CN",
    "COLOMBIA": "CO",
    "COMOROS": "KM",
    "CONGO (DRC)": "CD",
    "COSTA RICA": "CR",
    "CROATIA": "HR",
    "CUBA": "CU",
    "CYPRUS": "CY",
    "CZECH REPUBLIC": "CZ",
    "DENMARK": "DK",
    "DJIBOUTI": "DJ",
    "DOMINICA": "DM",
    "DOMINICAN REPUBLIC": "DO",
    "ECUADOR": "EC",
    "EGYPT": "EG",
    "EL SALVADOR": "SV",
    "EQUATORIAL GUINEA": "GQ",
    "ERITREA": "ER",
    "ESTONIA": "EE",
    "ESWATINI": "SZ",
    "ETHIOPIA": "ET",
    "FIJI": "FJ",
    "FINLAND": "FI",
    "FRANCE": "FR",
    "GABON": "GA",
    "GAMBIA": "GM",
    "GEORGIA": "GE",
    "GERMANY": "DE",
    "GHANA": "GH",
    "GREECE": "GR",
    "GRENADA": "GD",
    "GUATEMALA": "GT",
    "GUINEA": "GN",
    "GUINEA-BISSAU": "GW",
    "GUYANA": "GY",
    "HAITI": "HT",
    "HONDURAS": "HN",
    "HUNGARY": "HU",
    "ICELAND": "IS",
    "INDIA": "IN",
    "INDONESIA": "ID",
    "IRAN": "IR",
    "IRAQ": "IQ",
    "IRELAND": "IE",
    "ISRAEL": "IL",
    "ITALY": "IT",
    "JAMAICA": "JM",
    "JAPAN": "JP",
    "JORDAN": "JO",
    "KAZAKHSTAN": "KZ",
    "KENYA": "KE",
    "KIRIBATI": "KI",
    "KOREA": "KR",
    "KOSOVO": "XK",
    "KUWAIT": "KW",
    "KYRGYZSTAN": "KG",
    "LAOS": "LA",
    "LATVIA": "LV",
    "LEBANON": "LB",
    "LESOTHO": "LS",
    "LIBERIA": "LR",
    "LIBYA": "LY",
    "LIECHTENSTEIN": "LI",
    "LITHUANIA": "LT",
    "LUXEMBOURG": "LU",
    "MADAGASCAR": "MG",
    "MALAWI": "MW",
    "MALAYSIA": "MY",
    "MALDIVES": "MV",
    "MALI": "ML",
    "MALTA": "MT",
    "MARSHALL ISLANDS": "MH",
    "MAURITANIA": "MR",
    "MAURITIUS": "MU",
    "MEXICO": "MX",
    "MICRONESIA": "FM",
    "MOLDOVA": "MD",
    "MONACO": "MC",
    "MONGOLIA": "MN",
    "MONTENEGRO": "ME",
    "MOROCCO": "MA",
    "MOZAMBIQUE": "MZ",
    "MYANMAR": "MM",
    "NAMIBIA": "NA",
    "NAURU": "NR",
    "NEPAL": "NP",
    "NETHERLANDS": "NL",
    "NEW ZEALAND": "NZ",
    "NICARAGUA": "NI",
    "NIGER": "NE",
    "NIGERIA": "NG",
    "NORTH MACEDONIA": "MK",
    "NORWAY": "NO",
    "OMAN": "OM",
    "PAKISTAN": "PK",
    "PALAU": "PW",
    "PANAMA": "PA",
    "PAPUA NEW GUINEA": "PG",
    "PARAGUAY": "PY",
    "PERU": "PE",
    "PHILIPPINES": "PH",
    "POLAND": "PL",
    "PORTUGAL": "PT",
    "QATAR": "QA",
    "ROMANIA": "RO",
    "RUSSIA": "RU",
    "RWANDA": "RW",
    "SAINT KITTS AND NEVIS": "KN",
    "SAINT LUCIA": "LC",
    "SAINT VINCENT AND THE GRENADINES": "VC",
    "SAMOA": "WS",
    "SAN MARINO": "SM",
    "SAUDI ARABIA": "SA",
    "SENEGAL": "SN",
    "SERBIA": "RS",
    "SEYCHELLES": "SC",
    "SIERRA LEONE": "SL",
    "SINGAPORE": "SG",
    "SLOVAKIA": "SK",
    "SLOVENIA": "SI",
    "SOLOMON ISLANDS": "SB",
    "SOMALIA": "SO",
    "SOUTH AFRICA": "ZA",
    "SOUTH SUDAN": "SS",
    "SPAIN": "ES",
    "SRI LANKA": "LK",
    "SUDAN": "SD",
    "SURINAME": "SR",
    "SWEDEN": "SE",
    "SWITZERLAND": "CH",
    "SYRIA": "SY",
    "TAIWAN": "TW",
    "TAJIKISTAN": "TJ",
    "TANZANIA": "TZ",
    "THAILAND": "TH",
    "TIMOR-LESTE": "TL",
    "TOGO": "TG",
    "TONGA": "TO",
    "TRINIDAD AND TOBAGO": "TT",
    "TUNISIA": "TN",
    "TURKEY": "TR",
    "TURKMENISTAN": "TM",
    "TUVALU": "TV",
    "UGANDA": "UG",
    "UKRAINE": "UA",
    "UNITED ARAB EMIRATES": "AE",
    "UNITED KINGDOM": "UK",
    "UNITED STATES": "US",
    "URUGUAY": "UY",
    "UZBEKISTAN": "UZ",
    "VANUATU": "VU",
    "VENEZUELA": "VE",
    "VIETNAM": "VN",
    "YEMEN": "YE",
    "ZAMBIA": "ZM",
    "ZIMBABWE": "ZW"
  };
  
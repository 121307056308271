import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Checkbox } from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';

const PaymentConfirmation = () => {
    const [isChecked, setIsChecked] = useState(false);

    return (
        <div className="w-full px-1 pt-3 mx-auto">
            {/* Terms & Conditions */}
            <p className="mb-4 text-xs text-black">
                You must read and agree with the following in order to complete your reservation. Read an overview of all the airline rules and restrictions applicable to this fare.
                <span className="cursor-pointer text-primary"> Our Company's Terms & Conditions.</span>
            </p>

            {/* Checkbox */}
            <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="flex items-start mb-3 space-x-1"
            >

                <Checkbox
                    id="term"

                    checked={isChecked}
                    onChange={() => setIsChecked(!isChecked)}
                    sx={{
                        color: "#10A1D0",
                        '&.Mui-checked': {
                            color: "#10A1D0",
                        },
                        paddingBottom: "2px"
                    }}
                />
                <label hemlFor="term" className="text-xs text-black">
                    <span className="text-red-500">*</span> By checking this box you agree that you have read and accepted the
                    <span className="cursor-pointer text-primary"> Terms and Conditions, Cancellation Policies, and Privacy Policy.</span>
                    In addition, you confirm that you have verified that all information entered during the reservation process is accurate.
                    You also acknowledge that this will serve as your electronic signature. (Please check the box to continue)
                </label>
            </motion.div>

            {/* Payment Button */}
            <motion.button
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                disabled={!isChecked}
                className={`w-full py-3 gap-3 text-sm text-[#FDFDFD] flex items-center justify-center  font-bold rounded-md ${isChecked ? 'bg-primary hover:bg-primary/80' : 'bg-gray-400 cursor-not-allowed'}`}
            >
                <CreditCardIcon />
                BOOK & MAKE PAYMENT £1,100.00
            </motion.button>
        </div>
    );
};

export default PaymentConfirmation;

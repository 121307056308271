
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import { motion } from "framer-motion";

export default function StepIndicator({ steps = 4, currentStep = 2 }) {
    return (
        <div className="w-full py-6 mx-auto">
            <div className="relative">
                {/* Background line */}
                <div className="absolute left-0 right-0 h-[2px] transform -translate-y-1/2 bg-gray-300 rounded-full top-1/2" />

                {/* Animated progress line */}
                <motion.div
                    className="absolute left-0 h-[2px] transform -translate-y-1/2 bg-primary rounded-full top-1/2"
                    initial={false}
                    animate={{
                        width: `${((currentStep - 1) / (steps - 1)) * 100}%`,
                    }}
                    transition={{
                        duration: 0.5,
                        ease: "easeInOut",
                    }}
                />

                {/* Step indicators */}
                <div className="relative flex justify-between">
                    {[...Array(steps)].map((_, index) => {
                        const isCompleted = currentStep > index + 1;
                        const isCurrent = currentStep === index + 1;
                        return (
                            <div key={index} className="flex flex-col items-center space-y-2">
                                {/* Step circle */}
                                <motion.div
                                    initial={false}
                                    animate={{
                                        scale: isCurrent ? 1.1 : 1,
                                        borderWidth: isCurrent ? 1 : 1,
                                        backgroundColor: isCompleted ? "#10A1D0" : isCurrent ? "#10A1D0" : "#E5E7EB",
                                        borderColor: isCompleted ? "#10A1D0" : "#D1D5DB",
                                    }}
                                    className={`flex items-center justify-center w-[35px] h-[35px] rounded-full  transition-all duration-300 ${isCurrent ? "" : ""
                                        }`}
                                >
                                    {isCompleted ? (
                                        <svg
                                            className="w-5 h-5 text-white"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={3}
                                                d="M5 13l4 4L19 7"
                                            />
                                        </svg>
                                    ) : (
                                        <motion.span
                                            initial={false}
                                            animate={{
                                                color: isCurrent ? "white" : "#6B7280",
                                            }}
                                            className="text-sm font-bold"
                                        >
                                            <AirplanemodeActiveIcon className="rotate-90 h-[25px] w-[25px]" />
                                        </motion.span>
                                    )}
                                </motion.div>

                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

import React from 'react';
import CheckboxList from '../../../common/CheckboxList';

const StoprFilter = () => {
    const filterOptions = [
        { name: 'Non-Stop', key: 'nonStop' },
        { name: '1 Stop', key: 'oneStop' },
        { name: '1+ Stop', key: 'multipleStops' },
    ];

    return (
        <div className="w-[150px] flex flex-col gap-2 mx-auto">
            <h3 className="font-medium text-[12px] text-dark">Select Stops</h3>
            <CheckboxList options={filterOptions} />
        </div>
    );
};

export default StoprFilter;

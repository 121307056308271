import React from 'react';
import Slider from 'react-slick';
import { motion } from 'framer-motion';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const LogoSlider = ({ logos }) => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 5000, // Adjust speed for smoother scrolling
        autoplay: true,
        autoplaySpeed: 0, // Set to a small value for continuous scrolling
        slidesToShow: 9, // Number of logos to show at once
        slidesToScroll: 1,
        cssEase: 'linear', // Use linear easing for continuous scroll
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                },
            },
        ],
    };

    return (
        <div className="relative w-full overflow-hidden">
            <Slider {...settings}>
                {logos.map((logo, index) => (
                    <motion.div
                        key={index}
                        initial={{ opacity: 0, scale: 0.8 }} // Start with scaling effect
                        animate={{ opacity: 1, scale: 1 }} // Scale to normal
                        exit={{ opacity: 0, scale: 0.8 }} // Scale down when exiting
                        transition={{ duration: 0.5 }} // Adjust the duration as needed
                        className="flex items-center justify-center mx-2"
                    >
                        <img
                            src={`/images/logo/${logo}`} // Ensure logos are in the correct path
                            alt={`Logo ${index + 1}`}
                            className="object-contain h-[60px] w-[80px]" // Ensure logos fit well
                        />
                    </motion.div>
                ))}
            </Slider>
        </div>
    );
};

export default LogoSlider;

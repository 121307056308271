import { Checkbox, Slider } from '@mui/material';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setFilterChange } from '../../../../store/homepageFilterSlice';

const ConnectingTimeFilter = () => {
    // State for slider and checkboxes
    const [sliderValue, setSliderValue] = useState([1, 23]);
    const [selectedOptions, setSelectedOptions] = useState([false, false, false, false]);
    const [hasChanges, setHasChanges] = useState(false);

    const dispatch = useDispatch()
    const changeStatus = (data) => {
        dispatch(setFilterChange({
            name: "connection",
            data: data
        }))
    }

    const handleSliderChange = (event, newValue) => {
        setSliderValue(newValue);
        setHasChanges(true);
        changeStatus(true)
    };

    const handleCheckboxChange = (index) => {
        const newSelectedOptions = [...selectedOptions];
        newSelectedOptions[index] = !newSelectedOptions[index];
        setSelectedOptions(newSelectedOptions);
        setHasChanges(true);
        changeStatus(true)
    };

    const handleClearAll = () => {
        setSliderValue([1, 23]); // Reset slider
        setSelectedOptions([false, false, false, false]); // Uncheck all options
        setHasChanges(false);
        changeStatus(false)
    };

    useEffect(() => {
        // Update hasChanges based on initial states
        // const initialOptionsState = [false, false, false, false];
        const anySelected = selectedOptions.some((selected) => selected);
        const isSliderChanged = sliderValue[0] !== 1 || sliderValue[1] !== 23;

        setHasChanges(anySelected || isSliderChanged);
        changeStatus(anySelected || isSliderChanged)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sliderValue, selectedOptions]);

    return (
        <div className="flex min-w-[350px] flex-col w-full p-1 rounded-lg shadow-md">
            {/* Header with title */}
            <div className="flex items-center justify-between mb-4">
                <h2 className="text-[12px] font-semibold text-dark">Connecting Time</h2>
                <motion.button
                    onClick={handleClearAll}
                    className={`text-dark text-[12px] hover:underline ${hasChanges ? '' : 'opacity-0 pointer-events-none'}`}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: hasChanges ? 1 : 0 }}
                    transition={{ duration: 0.5 }}
                >
                    Clear
                </motion.button>
            </div>

            <div className='pt-5'>
                {/* Slider Section */}
                <motion.div
                    className="relative flex items-center px-3 mb-6"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                >
                    <span className="absolute text-base font-semibold left-2 -z-1 -top-5 text-dark">1 H</span>

                    <Slider
                        value={sliderValue}
                        onChange={handleSliderChange}
                        min={1}
                        max={23}
                        className="mx-4"
                        color="primary"
                        valueLabelDisplay="auto"
                        sx={{
                            color: '#10A1D0',
                            '& .MuiSlider-thumb': {
                                borderRadius: '50%',
                            },
                            '& .MuiSlider-rail': {
                                backgroundColor: '#10A1D0',
                            },
                            '& .MuiSlider-track': {
                                backgroundColor: '#10A1D0',
                            },
                        }}
                    />
                    <span className="absolute text-base font-semibold right-2 -z-1 -top-5 text-dark">23 H</span>
                </motion.div>

                {/* Connecting Options with Prices */}
                <div className="px-2 space-y-4 text-primary">
                    {['1Jomo Kenyatta International Airport (NBO)', '2Jomo Kenyatta International Airport (NBO)', '3Jomo Kenyatta International Airport (NBO)', '4Jomo Kenyatta International Airport (NBO)'].map((option, index) => (
                        <div key={index} className="flex items-center justify-between gap-2">
                            <Checkbox
                                checked={selectedOptions[index]}
                                onChange={() => handleCheckboxChange(index)}
                                color="primary"
                                size="small"
                                id={option}
                                sx={{
                                    color: "#10A1D0",
                                    '&.Mui-checked': {
                                        color: "#10A1D0",
                                    },
                                }}
                            />
                            <label
                                htmlFor={option}
                                className="text-[12px] cursor-pointer select-none md:text-[12px] text-dark font-[550]"
                            >
                                {option}
                            </label>
                            <span className="text-[12px] text-dark">£400.00</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ConnectingTimeFilter;

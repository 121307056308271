import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { motion } from "framer-motion";
import React from "react";
import { useNavigate } from 'react-router-dom';

const SearchedFlightCard = ({ imageUrl, title, price }) => {
    const navigate = useNavigate();
    return (
        <motion.div
            className="relative flex items-center justify-between w-full max-w-sm px-4 py-3 pb-10 transition-all duration-300 ease-in-out bg-white rounded-lg shadow-cardShadow hover:shadow-cardShadow"
        >
            {/* Left Section: Image */}
            <div className="flex-shrink-0">
                <motion.img
                    initial={{ opacity: 0, scale: 1 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 1 }}
                    src={`https://picsum.photos/200/300`}
                    alt={title}
                    className="object-cover w-16 h-16 border-2 rounded-full border-primary"
                />
            </div>

            {/* Middle Section: Location Icon, Title, and Price */}
            <div className="flex-grow px-4">
                <motion.div
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.8 }}
                    transition={{ duration: 1 }}
                    className="flex items-center">

                    <LocationOnIcon className="text-prmary" style={{ fontSize: '24px' }} />
                    <h3 className="ml-2 text-lg font-semibold text-gray-800">{title}</h3>
                </motion.div>
                <motion.p
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.8 }}
                    transition={{ duration: 1 }}
                    className="text-sm text-gray-600">
                    <p className="text-sm text-gray-600">
                        from <span className="font-bold text-[12px] text-primary">{price}</span>
                    </p>
                </motion.p>
            </div>

            {/* Right Section: Button */}
            <div className="absolute bottom-2 right-2">
                <button
                    onClick={() => navigate("/flight-filter")}
                    className="flex items-center px-4 py-2 text-[12px] text-white transition rounded-[12px] bg-primary hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300">
                    Search Now <ArrowForwardIcon className="ml-1" style={{ fontSize: '12px' }} />
                </button>
            </div>
        </motion.div>
    );
};

export default SearchedFlightCard;

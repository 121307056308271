import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Checkbox } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setFilterChange } from '../../../../store/homepageFilterSlice';

const NearbyAirportFilters = () => {
    const [selectedOption, setSelectedOption] = useState('LHR');
    const [selectedAirports, setSelectedAirports] = useState([]);
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(setFilterChange({
            name: "nearbyAirport",
            data: selectedAirports?.length > 0
        }))
    }, [selectedAirports, dispatch])


    // Define airport options for each tab
    const airportOptions = {
        LHR: [
            { label: 'Gatewick Airport 1', price: '£400.00' },
            { label: 'Gatewick Airport 2', price: '£450.00' },
            { label: 'Gatewick Airport 3', price: '£500.00' },
            { label: 'Gatewick Airport 4', price: '£550.00' },
        ],
        BOM: [
            { label: 'Chhatrapati Airport 1', price: '£600.00' },
            { label: 'Chhatrapati Airport 2', price: '£650.00' },
            { label: 'Chhatrapati Airport 3', price: '£700.00' },
            { label: 'Chhatrapati Airport 4', price: '£750.00' },
        ],
    };
    // Toggle selection of airports
    const toggleAirportSelection = (index) => {
        setSelectedAirports((prev) => {
            if (prev.includes(index)) {
                return prev.filter((i) => i !== index);
            } else {
                return [...prev, index];
            }
        });
    };

    // Clear selected airports
    const handleClear = () => {
        setSelectedAirports([]);
        dispatch(setFilterChange({
            name: "nearbyAirport",
            data: false
        }))
    };

    return (
        <div className='relative  min-w-[340px]'>
            <h3 className="mb-2 text-sm font-semibold text-dark">Nearby Airports</h3>
            <div className="w-full p-2 text-dark">
                {/* Tab Navigation */}
                <div className="flex mb-4">
                    <button
                        className={`px-4 py-2 ${selectedOption === 'LHR' ? 'bg-primary text-white' : 'bg-white text-[12px] text-gray-700'} focus:outline-none border border-r-0`}
                        onClick={() => {
                            setSelectedOption('LHR');
                            setSelectedAirports([]); // Reset selection when tab changes
                        }}
                    >
                        Near Airport to LHR
                    </button>
                    <button
                        className={`px-4 py-2 ${selectedOption === 'BOM' ? 'bg-primary text-white' : 'bg-white text-[12px] text-gray-700'} focus:outline-none border`}
                        onClick={() => {
                            setSelectedOption('BOM');
                            setSelectedAirports([]); // Reset selection when tab changes
                        }}
                    >
                        Near Airport to BOM
                    </button>
                </div>

                {/* Airport Filter List */}
                <div className="">
                    {/* Airport List */}
                    <div className="">
                        {airportOptions[selectedOption].map((option, index) => (
                            <div key={index} className="flex items-center justify-between font-medium">
                                <div className='flex items-center gap-1'>
                                    <Checkbox
                                        size="small"
                                        checked={selectedAirports.includes(index)}
                                        id={option.label}
                                        onChange={() => toggleAirportSelection(index)}
                                        sx={{
                                            color: "#10A1D0",
                                            '&.Mui-checked': {
                                                color: "#10A1D0",
                                            },
                                        }}
                                    />
                                    <label
                                        htmlFor={option.label}
                                        className="text-sm font-medium cursor-pointer select-none md:text-[12px]"
                                    >
                                        {option.label}
                                    </label>
                                </div>
                                <span className='text-[12px] '>{option.price}</span>
                            </div>
                        ))}
                    </div>

                    {/* Clear Button */}
                    {selectedAirports.length > 0 && (
                        <motion.button
                            className="absolute top-0 right-0 font-medium text-[13px] text-dark"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            onClick={handleClear}
                        >
                            Clear
                        </motion.button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default NearbyAirportFilters;

// src/components/StopInfo.js
import React from 'react';
import { motion } from 'framer-motion';

const StopInfo = ({ type = "main" }) => {
    return (
        <div className="flex items-end justify-center">
            <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="flex items-center space-x-3 "
            >

                <div className="text-center text-gray-700">
                    <p className="text-[11px] text-[#575756] font-medium">1 Stop</p>
                </div>

                {/* Divider Line */}
                <div className="relative flex items-center justify-center">
                    <div className="w-px h-12 bg-gray-300"></div>
                    <div className='absolute z-10 w-4 h-4 bg-white border rounded-full -bottom-2 shadow-cardShadow'>
                    </div>
                </div>

                {/* Duration Info */}
                <div className="text-center text-gray-700">
                    <p className="text-[11px] text-[#575756] font-medium">13 H 20 M</p>
                </div>
                {/* <motion.div
                    initial={{ width: 0 }}
                    animate={{ width: '100%', x: -15 }}
                    transition={{ duration: 0.5, delay: 0.5 }}
                    className="absolute bottom-0  -z-[1] left-0 border-t border-gray-300 "
                /> */}
            </motion.div>

            {/* Horizontal Lines */}
            {type !== "payment" && <motion.div
                initial={{ width: 0 }}
                animate={{ width: '100%', x: 25 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="absolute bottom-0 -z-[1] left-0 mx-auto border-t border-gray-300 max-w-[70%]"
            />}

        </div>
    );
};

export default StopInfo;

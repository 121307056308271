import { Checkbox } from '@mui/material';
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { setFilterChange } from '../../../../store/homepageFilterSlice';
import { useDispatch } from 'react-redux';

// Static country flags and their logos
const countryFlags = {
    airIndia: { name: 'India', logo: 'https://flagcdn.com/in.svg' },
    virgin: { name: 'United Kingdom', logo: 'https://flagcdn.com/gb.svg' },
    delta: { name: 'United States', logo: 'https://flagcdn.com/us.svg' },
    american: { name: 'United States', logo: 'https://flagcdn.com/us.svg' },
    lufthansa: { name: 'Germany', logo: 'https://flagcdn.com/de.svg' },
};

// Static airlines data
const airlines = [
    { name: 'Air India', key: 'airIndia', price: "$150" },
    { name: 'Virgin Atlantic', key: 'virgin', price: "$100" },
    { name: 'Delta Airlines', key: 'delta', price: "$150" },
    { name: 'American Airlines', key: 'american', price: "$100" },
    { name: 'Lufthansa', key: 'lufthansa', price: "$150" },
];

const AirlinesFilter = () => {
    const [checkedAirlines, setCheckedAirlines] = useState({});
    const isAnyChecked = Object.values(checkedAirlines).some((checked) => checked);
    const dispatch = useDispatch()
    const handleCheckboxChange = (key) => {
        setCheckedAirlines((prev) => ({
            ...prev,
            [key]: !prev[key],
        }));

        dispatch(setFilterChange({
            name: "airlines",
            data: Object.values({
                ...checkedAirlines,
                [key]: !checkedAirlines[key],
            }).some((checked) => checked)
        }))
    };

    // Handle clear button click
    const handleClear = () => {
        setCheckedAirlines({});
        dispatch(setFilterChange({
            name: "airlines",
            data: false
        }))
    };

    return (
        <div className='w-[250px] relative font-lato'> {/* Added relative positioning */}
            <h3 className="text-dark pb-1 text-[12px]">Select Airlines</h3>
            <div className="bg-white rounded ">
                {airlines.map((airline) => {
                    const country = countryFlags[airline.key];

                    return (
                        <div key={airline.key} className="flex items-center">
                            <Checkbox
                                id={airline.key}
                                size="small"
                                checked={!!checkedAirlines[airline.key]}
                                onChange={() => handleCheckboxChange(airline.key)}
                                sx={{
                                    color: "#10A1D0",
                                    '&.Mui-checked': {
                                        color: "#10A1D0",
                                    },
                                }}
                            />
                            {country && country.logo && (
                                <img
                                    src={country.logo}
                                    alt={country.name}
                                    className="w-6 h-6 mr-2"
                                />
                            )}
                            <div className='flex justify-between w-full'>
                                <label htmlFor={airline.key} className="cursor-pointer text-black text-[12px] font-[550] !font-lato">
                                    {airline.name}
                                </label>

                                <span className='font-[550] text-dark text-[12px]'>{airline?.price}</span>
                            </div>
                        </div>
                    );
                })}
                {/* Clear Text with Animation */}
                {isAnyChecked && (
                    <motion.div
                        initial={{ opacity: 0, translateY: -15 }}
                        animate={{ opacity: 1, translateY: -15 }}
                        exit={{ opacity: 0, translateY: -15 }}
                        transition={{ duration: 0.3 }}
                        className='text-dark absolute  top-3 right-1 text-[12px]'


                        onClick={handleClear}
                    >
                        Clear
                    </motion.div>
                )}
            </div>
        </div>
    );
};

export default AirlinesFilter;

import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import React from 'react'

const ContactCard = ({ type = "header" }) => {
    return (
        <div className={`${type === "header" ? "hidden lg:flex" : "flex"} h-full !text-white gap-2 relative pr-5 pt-1`}>
            <div className='relative flex items-center'>
                <svg width="35" height="40" viewBox="0 0 35 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.8081 35.348L14.8268 35.3553C16.012 36.7928 17.6164 37.8459 19.3751 38.3412C21.1339 38.8368 22.9415 38.7444 24.4994 38.0798L25.8554 37.5038C26.4606 37.2457 26.907 36.748 27.0966 36.1207C27.2862 35.4933 27.2035 34.7875 26.8665 34.1581L23.6899 28.2299C23.3524 27.6007 22.7885 27.0999 22.1221 26.8374C21.4557 26.5749 20.7413 26.5722 20.1358 26.8299C19.5299 27.0878 18.8151 27.0848 18.1482 26.8222C17.4813 26.5595 16.917 26.0586 16.5793 25.429L11.494 15.9355C11.157 15.3063 11.0744 14.6003 11.264 13.9729C11.4536 13.3455 11.8999 12.8481 12.5051 12.5898C13.1103 12.3315 13.5567 11.834 13.7463 11.2067C13.9359 10.5793 13.8532 9.87345 13.5162 9.24406L10.3396 3.31586C10.1732 3.00247 9.9488 2.71725 9.67931 2.47663C9.40979 2.23602 9.10053 2.04473 8.76929 1.91379C8.43802 1.78286 8.09132 1.71485 7.74908 1.71365C7.40687 1.71247 7.07588 1.77815 6.77514 1.90691L5.41914 2.48293C3.86127 3.14804 2.64607 4.34528 1.97397 5.8772C1.30185 7.4091 1.21296 9.18418 1.72195 10.9097L1.71729 10.9251C4.53957 19.6313 8.98338 27.922 14.8081 35.348Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <ellipse cx="30.027" cy="23" rx="4.09132" ry="4" fill="#03FB3A" />
                </svg>
            </div>

            <div className='relative pt-5'>
                <div className='absolute top-0'>
                    <span className="text-sm font-normal sm:text-base lg:text-[11px]">
                        Call Our Expert
                    </span>
                </div>
                <h2 className="font-bold md:hidden !text-base !sm:text-lg !md:text-xl !lg:text-[26px]">
                    0791-632-5248
                </h2>
                <h2 className="hidden text-[24px] font-bold md:block ">
                    0791-632-5248
                </h2>
                <div className='absolute -right-5 top-0'>
                    <span className="pr-5 text-sm font-normal sm:text-base lg:text-[11px]">
                        WhatsAPP
                    </span>
                    <div className='absolute animate-bounce -top-3 right-1'>
                        <WhatsAppIcon size={20} className='!h-4 !w-4' />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ContactCard

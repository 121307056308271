import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Autocomplete, Box, TextField } from '@mui/material';
import React from 'react';
import { countries } from '../../../../../assets/data';

export const ContactForm = ({ formData, handleChange, handleBlur, errors }) => {

    const validateField = (field, value) => {
        switch (field) {
            case 'email':
                return /\S+@\S+\.\S+/.test(value); // Simple email validation
            case 'countryCode':
                return value.trim() !== '';
            case 'phoneNumber':
                return /^\d{7,15}$/.test(value); // Assuming phone number should be 7-15 digits
            default:
                return true;
        }
    };

    const renderCheckIcon = (field) => {
        return validateField(field, formData[field]) ? (
            <CheckCircleIcon className="bg-white" style={{ color: 'green', position: 'absolute', right: 8, top: '50%', transform: 'translateY(-50%)' }} />
        ) : null;
    };

    const customTextFieldStyles = {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#10A1D0',
            },
            '&:hover fieldset': {
                borderColor: '#10A1D0',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#10A1D0',
            },
            height: '50px',
            '& .MuiOutlinedInput-root': {
                height: '46px',
                fontSize: '16px',
                '& input': {
                    height: '46px',
                    padding: '0 14px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    borderRadius: '8px',
                },
            },
        },
    };

    return (
        <div className="w-full mx-auto">
            <form className="grid grid-cols-1 gap-2 md:grid-cols-9 md:gap-3">

                {/* Email Address */}
                <div className="relative col-span-3">
                    <TextField
                        sx={customTextFieldStyles}
                        label="Email address"
                        value={formData.email}
                        onChange={(e) => handleChange(e, 'email')}
                        onBlur={() => handleBlur('email')}
                        error={!!errors.email}
                        helperText={errors.email && ''}
                        fullWidth
                    />
                    {renderCheckIcon('email')}
                </div>

                {/* Country Code */}
                <div className="relative col-span-2">

                    <Autocomplete
                        id="country-select-demo"
                        sx={customTextFieldStyles}
                        options={countries}
                        autoHighlight
                        getOptionLabel={(option) => option.label}

                        renderOption={(props, option) => {
                            const { key, ...optionProps } = props;
                            return (
                                <Box
                                    key={key}
                                    component="li"
                                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                    {...optionProps}
                                >
                                    <img
                                        loading="lazy"
                                        width="20"
                                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                        alt=""
                                    />
                                    {/* {option.label}  */}
                                    {option.code}
                                </Box>
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Country Code"
                                slotProps={{
                                    htmlInput: {
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    },
                                }}
                            />
                        )}
                    />
                    {renderCheckIcon('countryCode')}
                </div>

                {/* Phone Number */}
                <div className="relative col-span-4">
                    <TextField
                        sx={customTextFieldStyles}
                        label="Phone number"
                        value={formData.phoneNumber}
                        onChange={(e) => handleChange(e, 'phoneNumber')}
                        onBlur={() => handleBlur('phoneNumber')}
                        error={!!errors.phoneNumber}
                        helperText={errors.phoneNumber && ''}
                        fullWidth
                    />
                    {renderCheckIcon('phoneNumber')}
                </div>
            </form>
        </div>
    );
};

export default ContactForm;

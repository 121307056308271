import React from 'react';
import Slider from 'react-slick';
import { motion } from 'framer-motion';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ImageSlider = ({ images }) => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        cssEase: 'linear'
    };

    return (
        <div className="w-full   lg:h-[520px] overflow-hidden relative">
            <Slider {...settings}>
                {images.map((image, index) => (
                    <motion.div
                        key={index}
                        initial={{ opacity: 0, x: 0 }} // Start from the right
                        animate={{ opacity: 1, x: 0 }}   // Slide in from the right
                        exit={{ opacity: 0, x: 0 }}     // Slide out to the left
                        transition={{ duration: 1 }} // Adjust duration as needed
                        className="w-full h-full"
                    >
                        <img
                            src={`/images/${image}`}
                            alt={`Slide ${index + 1}`}
                            className="object-cover !min-h-[100vh] md:!min-h-[500px] w-full h-full transition-transform duration-300 ease-in-out"
                        />
                    </motion.div>
                ))}
            </Slider>
        </div>
    );
};

export default ImageSlider;

import CloseIcon from '@mui/icons-material/Close'
import ContactSupportIcon from '@mui/icons-material/ContactSupport'
import FlightIcon from '@mui/icons-material/Flight'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import MenuIcon from '@mui/icons-material/Menu'
import PhoneIcon from '@mui/icons-material/Phone'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import { Button } from '@mui/material'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useState } from 'react'
import ContactCard from './home/ContactCard'
import { NavLink } from 'react-router-dom';

const navOptions = [
    { name: 'Flights', path: '/', icon: <FlightIcon /> },
    { name: 'Hot Deals', path: '/hot-deals', icon: <LocalOfferIcon /> },
    { name: 'Contact Us', path: '/contact-us', icon: <ContactSupportIcon /> },
];

export default function Header() {
    const [menuOpen, setMenuOpen] = useState(false)
    const [selectedOption, setSelectedOption] = useState('Flights')
    console.log("🚀 ~ Header ~ selectedOption:", selectedOption)
    const handleNavigation = (option) => {
        setSelectedOption(option?.name)
        setMenuOpen(false)

    }

    const toggleMenu = () => {
        setMenuOpen(!menuOpen)
    }
    const NavButton = ({ option, icon, type = "mobile" }) => (
        <Button
            variant="ghost"
            className={`flex items-center space-x-2 text-xs sm:text-base md:text-lg transition-colors duration-300 ${selectedOption === option
                ? `bg-gradient-to-r from-[#10A1D0] to-[#56CCF2] ${type === "mobile" ? "!text-white" : "!text-white"}`
                : `bg-transparent hover:bg-primary hover:bg-opacity-10 hover:text-primary-foreground ${type === "mobile" ? "!text-primary" : "!text-white"}`
                }`}
            onClick={() => {
                handleNavigation(option);
            }}
        >
            {icon}
            <span className="text-sm sm:text-base md:text-lg">{option}</span>
        </Button>


    );


    return (
        <header className="sticky top-0 z-50 w-full pt-1 shadow-md bg-primary font-lato">
            <div className="w-full max-w-[1312px]  mx-auto py-1">
                <div className="flex items-center justify-between">
                    {/* Logo */}
                    <motion.div
                        className="cursor-pointer"
                        onClick={() => handleNavigation('Flights')}
                        whileTap={{ scale: 0.95 }}
                    >

                        <NavLink
                            to={"/"}
                            className={"text-2xl font-normal text-white sm:text-[18px] md:text-4xl"}
                        >

                            TravelTix
                        </NavLink>

                    </motion.div>

                    {/* Desktop Navigation */}
                    <nav className="hidden space-x-2 lg:flex">
                        {navOptions.map((option) => (
                            <motion.div
                                key={option.name}

                                transition={{ duration: 0.3, ease: 'easeInOut' }} // Smooth transition
                                className={`flex items-center text-lg p-2`}
                                onClick={() => handleNavigation(option)}
                            >
                                <NavLink
                                    to={option.path}

                                    className={`flex cursor-pointer transition-all duration-500 items-center pb-2 text-white 
                                    ${selectedOption === option.name ? 'font-semibold' : 'font-normal'}
                                    `}
                                >
                                    {option.icon}
                                    <span className="ml-2 text-[18px]">{option.name}</span>
                                </NavLink>
                            </motion.div>
                        ))}
                    </nav>

                    {/* Contact Information */}
                    {/* watsapp logo mobile */}
                    <ContactCard />


                    {/* Mobile Menu Button */}
                    <div className='lg:hidden'>
                        <Button
                            variant="ghost"
                            size="icon"
                            className="text-white md:hidden"
                            onClick={toggleMenu}
                            aria-label={menuOpen ? 'Close menu' : 'Open menu'}
                        >

                            {menuOpen ? (
                                <motion.div
                                    key="close"
                                    initial={{ rotate: -90 }}
                                    animate={{ rotate: 0 }}
                                    exit={{ rotate: 90 }}
                                    transition={{ duration: 0.2, ease: "easeInOut" }} // Smooth transition
                                >
                                    <CloseIcon className="w-6 h-6 text-white" />
                                </motion.div>
                            ) : (
                                <motion.div
                                    key="menu"
                                    initial={{ rotate: 90 }}
                                    animate={{ rotate: 0 }}
                                    exit={{ rotate: -90 }}
                                    transition={{ duration: 0.2, ease: "easeInOut" }} // Smooth transition
                                >
                                    <MenuIcon className="w-6 h-6 text-white" />
                                </motion.div>
                            )}
                        </Button>
                    </div>

                </div>

                {/* Mobile Navigation */}
                <AnimatePresence>
                    {menuOpen && (
                        <motion.div
                            initial={{ height: 0, opacity: 0 }}
                            animate={{ height: 'auto', opacity: 1 }}
                            exit={{ height: 0, opacity: 0 }}
                            transition={{ duration: 0.3 }}
                            className="mt-2 overflow-hidden bg-white rounded-md lg:hidden"
                        >
                            <nav className="flex flex-col p-4 space-y-2">
                                {navOptions.map((option) => (
                                    <NavButton key={option.name} option={option.name} icon={option.icon} type="mobile" />
                                ))}
                            </nav>
                            <div className="flex items-center justify-between p-4 border-t border-gray-200">
                                <motion.div
                                    className="flex items-center space-x-2"
                                    whileHover={{ scale: 1.05 }}
                                >
                                    <PhoneIcon className="w-6 h-6 text-primary" />
                                    <div>
                                        <div className="text-xs text-gray-600">Call Our Expert</div>
                                        <div className="text-sm font-bold">0791-632-5248</div>
                                    </div>
                                </motion.div>
                                <motion.div
                                    className="flex flex-col items-end"
                                    whileHover={{ scale: 1.05 }}
                                >
                                    <span className="text-xs text-gray-600">WhatsApp</span>
                                    <WhatsAppIcon className="h-6 w-6 text-[#03fb3a]" />
                                </motion.div>
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </header >
    )
}
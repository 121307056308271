import React from 'react'
import PriceBreakdown from '../flightFilter/PriceBreakdown'
import CountDownTimer from './right/CountDownTimer'

const RightSideSection = () => {
    return (
        <div className='sticky top-[100px] flex flex-col gap-2'>
            <div className='border border-gray-300 rounded-md shadow-cardShadow font-lato'>
                <div className='w-full p-2 border-b border-gray-300 '>
                    <span className='py-2  !font-bold text-black text-[15px]'>
                        Review fare summery
                    </span>
                </div>
                <div className='p-2 px-4 '>
                    <PriceBreakdown isType2={true} />
                </div>
            </div>
            <CountDownTimer />
        </div>
    )
}

export default RightSideSection
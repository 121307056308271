/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';

export default function ScrollableTable() {
    const scrollContainerRef = useRef(null);
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(true);

    const scroll = (direction) => {
        const container = scrollContainerRef.current;
        if (container) {
            const scrollAmount = container.clientWidth;
            container.scrollBy({ left: direction * scrollAmount, behavior: 'smooth' });
        }
    };

    const checkScroll = () => {
        const container = scrollContainerRef.current;
        if (container) {
            setCanScrollLeft(container.scrollLeft > 0);
            setCanScrollRight(container.scrollLeft < container.scrollWidth - container.clientWidth);
        }
    };

    // Sample data (fares may have missing data)
    const tableData = [
        {
            type: 'Non-stop',
            fares: ['£399.50', '£450.00'], // Example: Only 2 fares provided
        },
        {
            type: '1+Stop',
            fares: ['--', '£1,500.00'], // Example: Only 2 fares provided
        },
        // Add more rows as needed
    ];

    // Dynamic heading data (can be passed as props if needed)
    const headings = [
        { title: 'AI', imageUrl: 'images/logo/filters/ai.svg' },
        { title: 'Fare 1', imageUrl: 'images/logo/filters/ai.svg' },
        { title: 'Fare 2', imageUrl: 'images/logo/filters/ai.svg' },
        { title: 'Fare 3', imageUrl: 'images/logo/filters/ai.svg' },
        { title: 'Fare 4', imageUrl: 'images/logo/filters/ai.svg' },
        { title: 'Fare 5', imageUrl: 'images/logo/filters/ai.svg' },
        { title: 'Fare 6', imageUrl: 'images/logo/filters/ai.svg' },
        { title: 'Fare 7', imageUrl: 'images/logo/filters/ai.svg' },
        { title: 'Fare 8', imageUrl: 'images/logo/filters/ai.svg' },
        { title: 'Fare 9', imageUrl: 'images/logo/filters/ai.svg' },
        { title: 'Fare 10', imageUrl: 'images/logo/filters/ai.svg' },
        // { title: 'Fare 11', imageUrl: 'images/logo/filters/ai.svg' },
    ];

    return (
        <div className="w-full mx-auto min-w-[1312px]">
            <div className="relative overflow-hidden bg-white ">
                <div className="flex items-center justify-end gap-3 border-gray-300 border-x">
                    <div className="flex items-center gap-3 pr-4">
                        <button
                            onClick={() => scroll(-1)}
                            className='text-gray-400'>
                            &#9664;
                        </button>
                        {/* old MUI */}
                        {/* <Button
                            variant="outline"
                            size="icon"
                            onClick={() => scroll(-1)}
                            disabled={!canScrollLeft}
                            className="bg-gray-200 rounded-full hover:bg-gray-300"
                            aria-label="Scroll left"
                        >
                        </Button>
                        <Button
                            variant="outline"
                            size="icon"
                            onClick={() => scroll(1)}
                            disabled={!canScrollRight}
                            className="bg-gray-200 rounded-full hover:bg-gray-300"
                            aria-label="Scroll right"
                        >
                        </Button> */}

                        <button
                            onClick={() => scroll(1)}
                            className='text-gray-400'>
                            &#9654;
                        </button>
                    </div>
                </div>
                <div className="relative flex overflow-x-auto" ref={scrollContainerRef} onScroll={checkScroll}>
                    <table className="min-w-full border-collapse">
                        <thead>
                            <tr className="text-gray-700 bg-gray-100 border-b border-gray-300">
                                {/* Sticky Header (Show All Fares) */}
                                <th className="sticky text-[13px] py-4 left-0 z-10 p-3 font-bold  text-black bg-gray-100 text-end border-l border-gray-300 border-r min-w-[146px] border-t">
                                    Show All Fares
                                </th>

                                {/* Dynamically render headings */}
                                {headings.map((heading, index) => (
                                    <th key={index} className="p-1 py-4 text-xs border-t font-semibold text-left border-gray-300 border-x whitespace-nowrap min-w-[100px]">
                                        {/* Render the image if available */}
                                        {heading.imageUrl && (
                                            <img src={heading.imageUrl} alt={`${heading.title} Icon`} className="inline-block w-5 h-5 mr-2" />
                                        )}
                                        {heading.title}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map((row, rowIndex) => (
                                <tr key={rowIndex} className="border-b border-gray-300 hover:bg-gray-50">
                                    <td className="sticky text-[13px] left-0 z-10 p-2 font-medium bg-white border-gray-300 border-r text-end whitespace-nowrap border-l">
                                        {row.type}
                                    </td>

                                    {/* Ensure every row has the same number of columns as headings */}
                                    {headings.map((_, colIndex) => (
                                        <td key={colIndex} className="p-2 text-xs text-center border-r border-gray-300 whitespace-nowrap">
                                            {/* Render fare or "--" if fare is missing */}
                                            {row.fares[colIndex] ? row.fares[colIndex] : ''}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

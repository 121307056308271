import { Checkbox } from '@mui/material';
import React from 'react';

const FlightCheckbox = ({ id, label, checked, onChange }) => {
    return (
        <div className="flex items-center gap-3 mx-2">
            <Checkbox
                id={id}
                checked={checked}
                onChange={onChange}
                className='!h-[14px] !w-[14px]'
                sx={{
                    color: "#10A1D0",
                    '&.Mui-checked': {
                        color: "#10A1D0",
                    },
                }}
            />
            <label htmlFor={id} className="text-[13px] cursor-pointer select-none text-primary">
                {label}
            </label>
        </div>
    );
};

export default FlightCheckbox;

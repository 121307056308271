import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import { motion } from 'framer-motion';
import React from 'react';
import { FaSuitcase } from 'react-icons/fa';

const FlightSegment = ({ departure, arrival, stopOver, type = "" }) => (
    <div className={`p-2 text-black bg-white min-w-[300px] md:min-w-[500px]  ${type === "return" ? "border-t-2" : ""}`}>
        <div className="flex items-center justify-between">
            <div>
                <div className='flex items-center gap-2 '>
                    <p className="text-xl font-bold">{departure.time}</p>
                    <p className="text-sm text-gray-600">{departure.date}</p>
                </div>
                <p className="text-md">{departure.airport}</p>
            </div>
            <motion.div
                initial={{ scale: 0.5 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5 }}
            >
                <AirplanemodeActiveIcon className={`text-2xl text-primary  ${type === "return" ? "-rotate-90" : "rotate-90"}`} />
            </motion.div>
            <div className="text-right">
                <div className='flex items-center gap-2 '>
                    <p className="text-xl font-bold">{arrival.time}</p>
                    <p className="text-sm text-gray-600">{arrival.date}</p>
                </div>

                <p className="text-md">{arrival.airport}</p>
            </div>
        </div>
        <div className='flex justify-between'>
            {stopOver && (
                <p className="text-sm text-primary">Stop Over: {stopOver}</p>
            )}
            <div className="flex items-center ">
                <FaSuitcase className="mr-2 text-gray-500" />
                <p className="text-sm text-gray-600">2 PC</p>
            </div>
        </div>
    </div>
);

const HoverCard = ({ outbound, inbound }) => {
    return (
        <div className="w-full bg-white mx-auto min-w-[300px]">
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
            >
                {outbound.map((segment, index, arr) => {

                    return (
                        <FlightSegment type="start" key={`outbound-${index}`} {...segment} />
                    )
                })}
            </motion.div>

            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
            >
                {inbound.map((segment, index) => (
                    <FlightSegment type="return" key={`inbound-${index}`} {...segment} />
                ))}
            </motion.div>
        </div>
    );
};

export default function FlightHoverCard() {
    const flightData = {
        outbound: [
            {
                departure: { time: '16:25', date: 'Sun, 22 Sep', airport: 'LHR - London Heathrow' },
                arrival: { time: '04:15', date: 'Mon, 23 Sep', airport: 'DEL - Delhi' },
                stopOver: '(DEL) 11:50 H'
            },
            {
                departure: { time: '16:05', date: 'Mon, 23 Sep', airport: 'DEL - Delhi' },
                arrival: { time: '21:15', date: 'Mon, 23 Sep', airport: 'BOM - Mumbai' },
                stopOver: null
            }
        ],
        inbound: [
            {
                departure: { time: '23:55', date: 'Sun, 29 Sep', airport: 'BOM - Mumbai' },
                arrival: { time: '05:30', date: 'Mon, 30 Sep', airport: 'LHR - London Heathrow' },
                stopOver: null
            }
        ]
    };

    return <HoverCard {...flightData} />;
}
import React from 'react';

const InfoBanner = () => {
    const data = [
        {
            title: 'Best Price Guarantee',
            subtitle: '',
            imagePath: '/images/logo/right-tick.svg',
        },
        {
            title: 'Quickly & Securely',
            subtitle: 'Pay with a QR code',
            imagePath: '/images/logo/qr-code.svg',
        },
        {
            title: 'Over 13 Years in Business',
            subtitle: 'Est. 2010',
            imagePath: '',
        },
        {
            title: 'IATA Accredited Agency',
            subtitle: '',
            imagePath: '/images/logo/iata.svg',
        },
    ];

    return (
        <div className="flex items-center justify-center w-full py-2 font-lato">
            <div className='w-full max-w-[1312px]'>
                <div className="grid grid-cols-1 max-w-[1312px] w-full gap-6 md:grid-cols-2 lg:grid-cols-4">
                    {data.map((item, index) => (
                        <div key={index} className="flex flex-col items-center justify-between text-center">
                            {/* Title */}
                            <h3 className="text-lg text-[14px] font-medium text-primary">{item.title}</h3>

                            {/* Subtitle */}
                            {item.subtitle?.length > 0 && <p className={`${item.imagePath?.length > 0 ? "text-sm text-primary" : "text-sm text-primary text-[10px] px-3 border-primary inline-block border p-1 rounded-xl"}`}>{item.subtitle}</p>}

                            {/* Render the image dynamically */}
                            {item.imagePath?.length > 0 ? (
                                <img
                                    src={item.imagePath}
                                    alt={item.title}
                                    className="w-[30px] h-[30px] mx-auto mb-2" // Adjust the size of the image as needed
                                />
                            ) : <div></div>}

                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default InfoBanner;

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { format } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Don't forget to import the CSS
import { useSelector } from 'react-redux';

const DateRangePickerComponent = () => {

  const SD = new Date();
  SD.setDate(SD.getDate() + 1); 
  const ED = new Date(SD);
  ED.setDate(SD.getDate() + 7);

  const datePickerRef = useRef(null);
  const [dateRange, setDateRange] = useState([SD , ED]);
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const today = new Date();
  const maxDate = new Date();
  maxDate.setFullYear(today.getFullYear(), today.getMonth(), today.getDate() + 365);

  const handleDateChange = (dates) => {
    setDateRange(dates);
    if (dates[1]) { // Check if the end date is selected
      setIsOpenDropDown(false); // Close the dropdown
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
        setIsOpenDropDown(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [datePickerRef]);

  const toggleDropdown = () => {
    setIsOpenDropDown((prev) => !prev);
  };

  const [monthsShown, setMonthsShown] = useState(2);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) { // Adjust the breakpoint as needed
        setMonthsShown(1); // Mobile/tablet view
      } else {
        setMonthsShown(2); // Desktop view
      }
    };

    // Set the initial value
    handleResize();

    // Add the resize event listener
    window.addEventListener('resize', handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { roundTrip = "" } = useSelector(state => state?.homePage?.selectedOptions)

  const isReturnDisabled = roundTrip === "Oneway"

  return (
    <>
      {/* Depart Date Section */}
      <div className='w-full' ref={datePickerRef} onClick={toggleDropdown}>
        <div className="flex items-center justify-center space-x-2 md:justify-normal">
          <CalendarMonthIcon className="w-5 h-5 text-primary" />
          <div className='flex flex-col items-start'>
            <p className="font-medium text-[14px] text-primary">Depart Date</p>
            <div className='flex items-end text-black'>
              <p className="pr-1 text-lg font-medium">{format(dateRange[0], 'dd')}</p>
              <p className="pr-1 text-base font-medium">{format(dateRange[0], "MMM")} '</p>
              <p className="text-base font-medium">{format(dateRange[0], "yy")}</p>
            </div>
            <p className="text-[16px]  text-start max-w-[200px] truncate text-gray-600">{dateRange[0] ? format(dateRange[0], 'EEEE') : 'Not selected'}</p>
          </div>
        </div>
      </div>

      <div className="hidden border-l border-gray-300 md:block h-14" />

      {/* Return Date Section */}
      <div className={`w-full pl-3 ${isReturnDisabled ? "cursor-not-allowed" : "cursor-pointer"}`} ref={datePickerRef} onClick={isReturnDisabled ? () => { } : toggleDropdown}>
        <div className="flex items-center justify-center space-x-2 md:justify-normal">
          <CalendarMonthIcon className={`w-5 h-5  ${isReturnDisabled ? "text-gray-300" : "text-primary"}`} />
          <div className='flex flex-col items-start'>
            <p className={`font-medium text-[14px] ${isReturnDisabled ? "text-gray-300" : "text-primary"}`}>Return Date</p>
            <div className={`flex items-end ${isReturnDisabled ? "text-gray-300" : "text-black"}`}>
              <p className="pr-1 text-lg font-medium">{format(dateRange[1], 'dd')}</p>
              <p className="pr-1 text-base font-medium">{format(dateRange[1], 'MMM')}'</p>
              <p className="text-base font-medium">{format(dateRange[1], 'yy')}</p>
            </div>
            <p className={`text-[16px]  text-start max-w-[200px] truncate ${isReturnDisabled ? "text-gray-300" : "text-black"}`}>{dateRange[1] ? format(dateRange[1], 'EEEE') : 'Not selected'}</p>
          </div>
        </div>
      </div>

      {isOpenDropDown && (
        <div ref={datePickerRef} className="absolute top-[110px] z-[10] w-auto ">
          <DatePicker
            selected={dateRange[0]}
            onChange={handleDateChange}
            startDate={dateRange[0]}
            endDate={dateRange[1]}
            selectsRange={true}
            inline
            // popperClassName="custom-datepicker"
            // calendarClassName="custom-calendar"
            monthsShown={monthsShown}
            minDate={today}  // Restrict selection to today or later
            maxDate={maxDate}  // Restrict selection to 365 days from today
          />
        </div>
      )}
    </>
  );
};

export default DateRangePickerComponent;

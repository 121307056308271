import React from 'react'

const FinalFlightCard = ({ isBorder = false }) => {
    return (
        <>
            <div className='flex items-center justify-between w-full px-2 py-4'>
                <div className='flex items-end gap-2 text-black '>
                    <img

                        src={"images/logo/flight-2.svg"}
                        alt={"flight-2"}
                        className={`object-contain w-5 h-5 ${!isBorder ? "-rotate-90" : ""}`}
                    />
                    London to Mumbai On
                    <span className='text-[13px] text-[#575756]'>Sunday, 22 September, 2024</span>
                </div>
                <div className='text-[13px] text-[#575756]'>
                    Total Duration 8H: 45M
                </div>
            </div>
            <div className={`flex justify-between w-full pb-2  ${isBorder ? "border-b border-gray-300" : ""}`}>
                <div className="flex items-center w-full space-x-4">
                    <img src="/images/logo/filters/ai.svg" alt="Air India logo" className="object-contain w-6 h-6" />
                    <div>
                        <h3 className="text-[13px] text-dark font-medium">Air India</h3>
                    </div>
                </div>
                <div className='flex flex-col w-full gap-1'>
                    <div className="flex items-end space-x-2 text-sm">
                        <span className="font-mono text-base font-extrabold text-black">16:25</span>
                        <span className="text-sm font-semibold text-[#575757]">Sun, 22 Sep</span>
                    </div>
                    <div className="text-sm font-semibold text-[#575757]">LHR - London Heathrow</div>
                    <div className="text-sm text-[#616595] font-semibold">Terminal 4</div>
                </div>
                <div className="flex items-center justify-center w-full px-5 py-4 space-x-4">
                    {/* Left Line */}
                    <div className="flex-grow border-t border-gray-400"></div>

                    {/* Centered Time Text */}
                    <div className="text-sm text-gray-500">13 H 20 M</div>

                    {/* Right Line */}
                    <div className="flex-grow border-t border-gray-400"></div>
                </div>
                <div className='flex flex-col w-full gap-1'>
                    <div className="flex items-end space-x-2">
                        <span className="font-mono text-base font-extrabold text-black">16:25</span>
                        <span className="text-sm font-semibold text-[#575757]">Sun, 22 Sep</span>
                    </div>
                    <div className="text-sm font-semibold text-[#575757]">LHR - London Heathrow</div>
                    <div className="text-sm text-[#616595] font-semibold">Terminal 4</div>
                </div>

                <div className='w-full pr-2  gap-2 flex flex-col items-end  max-w-[180px]'>
                    <div className="text-sm font-semibold text-[#575757]">Class : Economy</div>
                    <div className="flex items-center space-x-2">

                        <img src="/images/logo/filters/bag.svg" alt="Baggage icon" className="object-contain w-4 h-6" />
                        <span className="text-xs font-medium text-[#575756]">2 PC</span>
                    </div>
                    <div className="text-sm font-semibold text-[#575757]">Baggage Included</div>
                </div>
            </div>
        </>
    )
}

export default FinalFlightCard
import React, { forwardRef } from 'react';
import { motion } from 'framer-motion';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import { countryCodes } from '../../../../assets/data';

const AirportDropdown = forwardRef(
    (
      {
        label,
        iconType,
        selectedAirport,
        searchQuery,
        airports,
        onSearchChange,
        onSelect,
        dropdownOpen,
        toggleDropdown,
      },
      ref
    ) => {
    console.log("🚀 ~ selectedAirport:", selectedAirport)
    return (
        <div className="relative w-full min-w-[200px] max-w-[200px]"> {/* Fixed width */}
            <div 
                className="flex items-center justify-between p-1 rounded-md cursor-pointer"
                onClick={toggleDropdown}
            >
                <div className="flex items-center space-x-3">
                    {iconType === 'takeoff' ? (
                        <FlightTakeoffIcon className="text-primary" />
                    ) : (
                        <FlightLandIcon className="text-primary" />
                    )}

                    <div className="truncate">
                        <div className="font-medium text-start text-[14px] text-primary">{label}</div>
                        {selectedAirport ? (
                            <div className="flex items-end font-medium text-black">
                                <span className="flex gap-1 pr-2 text-">
                                    {/* {selectedAirport.code} */}
                                    <input
                                        ref={ref}
                                        type="text"
                                        className="w-full rounded-md outline-none"
                                        placeholder="Search airport"
                                        value={searchQuery}
                                        onChange={onSearchChange}
                                    />
                                </span>
                                {/* {selectedAirport.city} */}
                            </div>
                        ) : (
                            <div className="flex items-end font-semibold text-black">
                                <span className="flex gap-1 pr-2 text-xl">
                                    Select Airport
                                </span>
                            </div>
                        )}
                        {selectedAirport && (
                            <div className="text-[16px]  text-start max-w-[200px] truncate text-gray-600">
                                {selectedAirport.city + ", "}
                                {selectedAirport.country && countryCodes[selectedAirport.country.toUpperCase()] 
                                    ? countryCodes[selectedAirport.country.toUpperCase()] 
                                    : selectedAirport.country}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {dropdownOpen && airports?.length > 0 && (
                <motion.div
                    className="absolute max-h-[250px] overflow-x-auto custom-scroll min-w-[300px] p-0.5 left-0 !z-[1111] w-full bg-white border border-gray-300 rounded-md shadow-lg top-full"
                    initial={{ opacity: 0, y: 0 }}
                    animate={{ opacity: 1, y: -5, x: 40 }}
                    transition={{ duration: 0.2 }}
                >

                    <ul >
                        {airports.slice(0, 20).map((airport) => (
                            <li
                                key={airport.code}
                                onClick={() => onSelect(airport)}
                                className="flex items-center p-3 cursor-pointer hover:bg-blue-100"
                            >
                                {iconType === 'takeoff' ? (
                                    <FlightTakeoffIcon className="mr-2 text-primary" />
                                ) : (
                                    <FlightLandIcon className="mr-2 text-primary" />
                                )}
                                <div className="truncate"> {/* Prevent text overflow */}
                                    <p className="font-semibold text-start">
                                        {airport.code} {airport.city}
                                    </p>
                                    <p className="text-xs text-gray-500">{airport.name}</p>
                                </div>
                            </li>
                        ))}
                    </ul>
                </motion.div>
            )}
        </div>
    );
});

export default AirportDropdown;

"use client"

import AddIcon from '@mui/icons-material/Add'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import RemoveIcon from '@mui/icons-material/Remove'
import { AnimatePresence, motion } from "framer-motion"
import { useEffect, useRef, useState } from "react"
import { useSelector } from 'react-redux'

export default function TravellerSelector() {
    const [isExpanded, setIsExpanded] = useState(false)
    const [travellers, setTravellers] = useState({
        adults: 1,
        children: 0,
        infants: 0,
    })
    const detailRef = useRef(null)

    const totalTravellers = Object.values(travellers).reduce((a, b) => a + b, 0)


    const updateTravellers = (type, increment) => {
        setTravellers((prev) => {
            const newCount = Math.max(0, prev[type] + increment)
            const totalCount = totalTravellers + increment

            // Apply the limits
            if (type === "children" && newCount > 3) return prev
            if (type === "infants" && newCount > 2) return prev
            if (totalCount > 9) return prev

            return {
                ...prev,
                [type]: newCount,
            }
        })
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (detailRef.current && !detailRef.current.contains(event.target)) {
                setIsExpanded(false)
            }
        }

        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [])

    const { anyClass = "" } = useSelector(state => state?.homePage?.selectedOptions)

    return (
        <div ref={detailRef} className="relative flex items-center justify-center w-full mx-auto">
            <button
                className="transition-shadow duration-300 cursor-pointer"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIsExpanded(!isExpanded);
                }}
            >
                <div className='flex items-center gap-2'>
                    <img
                        src={"/images/logo/traveller.svg"}
                        alt={"/images/logo/traveller.svg"}
                        className="object-contain h-[22px]"
                    />
                    <div className='flex flex-col items-start'>
                        <div className="font-medium text-[14px] text-primary">Travellers & Class</div>
                        <div className='flex items-center gap-1 font-medium text-black'>
                            <div>
                                {totalTravellers}

                            </div>
                            Traveller{totalTravellers !== 1 ? "s" : ""}
                            {isExpanded ? (
                                <ExpandLessIcon className="text-primary" />
                            ) : (
                                <ExpandMoreIcon className="text-primary" />
                            )}
                        </div>
                        <div className="text-[16px]  text-start max-w-[200px] truncate text-gray-600">{anyClass}</div>
                        <div>
                        </div>
                    </div>
                </div>
            </button>

            <AnimatePresence>
                {isExpanded && (
                    <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.2 }}
                        className="absolute left-12 p-2 z-10 w-full min-w-[230px] bg-white border rounded-lg shadow-lg border-primary top-14"
                    >
                        {Object.entries(travellers).map(([type, count]) => (
                            <div key={type} className="flex items-center justify-between p-2">
                                <div className="flex flex-col items-start">
                                    <span className="text-gray-700 capitalize">{type}</span>
                                    <span className="text-xs text-gray-400 capitalize">{type === "children" ? "2-11 Years" : type === "infants" ? "< 2 yrs" : ""}</span>

                                </div>
                                <div className="flex items-center space-x-2 sm:space-x-3">
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            updateTravellers(type, -1)
                                        }}
                                        className="p-1 transition-colors text-primary hover:bg-primary/40"
                                    >
                                        <RemoveIcon fontSize="small" />
                                    </button>
                                    <span className="font-semibold text-center text-primary">{count}</span>
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            updateTravellers(type, 1)
                                        }}
                                        disabled={(type === "children" && count >= 3) || (type === "infants" && count >= 2) || (totalTravellers >= 9)}
                                        className={`p-1 transition-colors text-primary ${((type === "children" && count >= 3) || (type === "infants" && count >= 2) || (totalTravellers >= 9)) ? 'opacity-50 cursor-not-allowed' : 'hover:bg-primary/40'}`}
                                    >
                                        <AddIcon fontSize="small" />
                                    </button>
                                </div>
                            </div>
                        ))}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    )
}

import { addDays, format, startOfWeek } from 'date-fns';
import { motion } from 'framer-motion';
import Tooltip from 'rc-tooltip';
import React, { useMemo, useState } from 'react';
import FlightBookingCard from './BookingCard';
import FlightHoverCard from './FlightHoverCard';

const flightTypes = ['Requested Date', 'Direct Flight', 'Flexible Date'];
const options = [
    { label: 'Cheapest', price: '£300.99', icon: '✈️' },
    { label: 'Fastest', price: '£1,500.00' },
    { label: 'Earliest', price: '£500.00' },
    { label: 'Alternate Date', price: '£500.00' },
    { label: 'Alternate airport', price: '£500.00' },
];

// Generates dynamic flight data for the week
const generateFlightData = (weekDates) => {
    const airlines = ['Air India', 'British Airways', 'Lufthansa', 'Emirates', 'Qatar Airways'];
    const types = ['Non Stop', 'Cheapest', 'Direct', 'One Stop'];
    const durations = ['2h 30m', '2h 45m', '2h 15m', '4h 30m', '2h 20m'];

    return weekDates.map((date, index) => ({
        date,
        flights: Array.from({ length: 5 }, (_, flightIndex) => ({
            price: `£${(350 + Math.random() * 200).toFixed(2)}`,
            airline: airlines[flightIndex % airlines.length],
            type: types[flightIndex % types.length],
            duration: durations[flightIndex % durations.length],
        })),
    }));
};

export default function FlightBookingFilterTable() {
    // Get dates for current week
    const getWeekDates = () => {
        const today = new Date();
        const startOfCurrentWeek = startOfWeek(today, { weekStartsOn: 1 });
        const weekDays = [];

        for (let i = 0; i < 6; i++) {
            const day = addDays(startOfCurrentWeek, i);
            weekDays.push(format(day, 'EEE dd MMM'));
        }

        return weekDays;
    };

    const dates = getWeekDates();
    const [activeFlightType, setActiveFlightType] = useState('Requested Date');
    const [activeOption, setActiveOption] = useState('Cheapest');

    // Generate dynamic flight data
    const flightData = useMemo(() => generateFlightData(dates), [dates]);

    const filteredFlightData = useMemo(() => {
        return flightData.map(row => ({
            ...row,
            flights: row.flights.filter(flight => {
                if (activeFlightType === 'Direct Flight' && !['Direct', 'Non Stop'].includes(flight.type)) {
                    return false;
                }
                if (activeOption === 'Cheapest' && flight.type !== 'Cheapest') {
                    return false;
                }
                if (activeOption === 'Fastest' && flight.duration !== '2h 15m') {
                    return false;
                }
                return true;
            })
        }));
    }, [activeFlightType, activeOption, flightData]);

    const [isTooltipVisible, setIsTooltipVisible] = useState(true);
    const [isCardVisible, setisCardVisible] = useState(false)
    console.log("🚀 ~ FlightBookingFilterTable ~ isCardVisible:", isCardVisible)

    const handleClick = () => {
        // Hide tooltip when td is clicked
        setIsTooltipVisible(false);
        setisCardVisible(true)

        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth',
        });

        setTimeout(() => setIsTooltipVisible(true), 1000);  // Show tooltip again after scroll
    };

    return (
        <div className="w-full mx-auto overflow-x-auto">
            {/* Flight Type Filter */}
            <div className="overflow-hidden">
                <div className="grid grid-cols-3 border border-gray-300 divide-x divide-gray-300">
                    {flightTypes.map((type) => (
                        <button
                            key={type}
                            className={`relative py-3 w-full text-sm  px-4  text-center transition-colors duration-300 
                            
                            ${activeFlightType === type ? 'text-black font-extrabold' : 'text-gray-500 font-semibold'
                                }`}
                            onClick={() => setActiveFlightType(type)}
                        >
                            {type}

                            {/* Underline animation */}
                            {activeFlightType === type && (
                                <div className='absolute bottom-0 left-0 flex justify-center w-full '>
                                    <motion.div
                                        layoutId="underline"
                                        className=" text-center max-w-[90%] left-0 w-full h-1 bg-primary"
                                        initial={false}
                                        transition={{ type: "spring", stiffness: 500, damping: 30 }}
                                    />
                                </div>
                            )}
                        </button>
                    ))}
                </div>
            </div>

            <div className="pt-1 overflow-hidden">
                <div className="grid grid-cols-5 border border-gray-300 divide-x divide-gray-300 rounded-sm">
                    {options.map((option, index) => (
                        <div
                            key={index}
                            className={`relative py-3 w-full cursor-pointer  px-4  text-center transition-colors duration-300 
                            
                            ${activeOption === option.label ? 'text-primary font-extrabold text-base' : 'text-sm  text-gray-500 font-semibold'
                                }`}
                            onClick={() => {
                                if (activeOption === option.label) {
                                    setActiveOption("")
                                } else {
                                    setActiveOption(option.label)
                                }
                            }}
                        >

                            <div className='flex items-center justify-center gap-2'>
                                {option.icon && <img

                                    src={"images/logo/filters/cash.svg"}
                                    alt={"images/logo/"}
                                    className="object-contain h-[30px] w-[30px] "
                                />}
                                <div className='flex flex-col items-start text-sm'>
                                    <p>
                                        {option.label}
                                    </p>
                                    <p>
                                        {option.price}
                                    </p>

                                </div>
                            </div>


                        </div>
                    ))}
                </div>
            </div>



            {/* Flight Data Table */}
            {activeFlightType === "Flexible Date" && <motion.div
                initial={{ opacity: 0, y: 0 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1.5 }}
                className="pt-1 overflow-x-auto">
                <table className="w-full border-collapse">
                    <thead className="sticky top-0 z-10 bg-white">
                        <tr className='h-[65px]'>
                            <th className="p-2 font-bold sticky z-[1] left-0 border min-w-[120px] border-gray-300 bg-[#F5F5F5]"></th>
                            {dates.map((date) => (
                                <th key={date} className="border min-h-[60px] border-gray-300 bg-[#F5F5F5] p-2 text-[13px] min-w-[120px]">{date}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {filteredFlightData.map((row) => (
                            <tr key={row.date} className='min-h-[65px]'>
                                <td className="p-2 text-[13px]  pl-4 font-bold sticky z-[1] left-0 border !min-w-[120px] border-gray-300 !min-h-[65px]  text-center bg-[#F5F5F5]"

                                >{row.date}</td>
                                {dates.map((date, index) => {
                                    const flight = row.flights[index];
                                    return (
                                        <motion.td
                                            key={index}
                                            className="p-0.5  border min-w-[120px] min-h-[65px] border-gray-300 cursor-pointer"
                                            style={{ boxShadow: "0 6px 16px 0 rgba(0, 0, 0, .28)" }}
                                            initial={{ opacity: 0, y: 0 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            transition={{ duration: 0.3, delay: index * 0.1 }}
                                            onClick={handleClick}
                                        >

                                            {flight ? (
                                                isTooltipVisible ? (  // Conditionally show tooltip
                                                    <Tooltip
                                                        key={index}
                                                        animation="zoom"
                                                        overlay={<FlightHoverCard />}
                                                        placement="rightTop"
                                                        // trigger={['click']}
                                                        arrowContent={<div className="" />}
                                                    >
                                                        <div className='flex flex-col items-center gap-1'>
                                                            <p className="text-base font-bold text-center text-black font-lato">{flight.price}</p>
                                                            <div className='flex items-start gap-2'>
                                                                <img
                                                                    key={index}
                                                                    src={"images/logo/filters/ai.svg"}
                                                                    alt={"filter"}
                                                                    className="oject-contain h-[20px] w-[20px]"
                                                                />
                                                                <div className='flex flex-col text-dark'>
                                                                    <span className="text-[13px]">{flight.airline}</span>
                                                                    <span className="text-[10px] font-semibold text-[#4C92A1]">{flight.type}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Tooltip>
                                                ) : (
                                                    // Display content without the tooltip if it's hidden
                                                    <div className='flex flex-col items-center gap-1'>
                                                        <p className="text-base font-bold text-center text-black font-lato">{flight.price}</p>
                                                        <div className='flex items-start gap-2'>
                                                            <img
                                                                key={index}
                                                                src={"images/logo/filters/ai.svg"}
                                                                alt={"filter"}
                                                                className="oject-contain h-[20px] w-[20px]"
                                                            />
                                                            <div className='flex flex-col text-dark'>
                                                                <span className="text-[13px]">{flight.airline}</span>
                                                                <span className="text-[10px] font-semibold text-[#4C92A1]">{flight.type}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            ) : (
                                                <div className="h-full p-2"></div>
                                            )}
                                        </motion.td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </motion.div >}
            {activeFlightType === "Flexible Date" ? <FlightBookingCard visible={isCardVisible} />
                : [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]?.map(item => {
                    return <FlightBookingCard visible={true} />
                })
            }
        </div >
    );
}

import React, { useState } from 'react'
import CommonModal from '../../../common/CommonModal'
import StepIndicator from '../../../common/StepIndicator'
import FlightSearchFloatingFilter from '../Flights/FlightSearchFloatingFilter'
import FlightScrollTable from './FlightScrollTable'
import ScrollableTable from './ScrollableTable'
import FlightBookingFilterTable from './FlightBookingFilterTable'

const FlightFilterPage = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    return (
        <div className='flex flex-col pb-5 items-center max-w-[1312px] justify-center w-full gap-2 mx-auto'>
            <div className='w-full py-2 max-w-[1312px] '>
                <FlightSearchFloatingFilter isSecondary={true} onClick={toggleModal} />
            </div>
            <StepIndicator currentStep={2} />
            <CommonModal isOpen={isModalOpen} onClose={toggleModal}>
                <FlightSearchFloatingFilter isSecondary={false} />
            </CommonModal>
            <div className='flex flex-col max-w-[1312px]'>
                <FlightScrollTable />
                <ScrollableTable />
            </div>
            <FlightBookingFilterTable />
        </div>
    )
}

export default FlightFilterPage
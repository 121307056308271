import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setHomePageSelectedOptions } from '../../../../store/homepageFilterSlice';
import FlightCheckbox from '../../../common/FlightCheckbox';
import CustomFlightDropdown from './CustomFlightDropdown';

const FlightSelectBarFilters = ({airlines}) => {
    // Create an object state to hold selected options for each dropdown
    const [selectedOptions, setSelectedOptions] = useState({
        roundTrip: 'Round Trip',
        allAirlines: 'All Airlines',
        anyClass: 'Any Class',
    });
    // const { selectedOptions } = useSelector(state => state?.homePage.selectedOptions)
    const dispatch = useDispatch()
    const setStoreData = (data) => { dispatch(setHomePageSelectedOptions(data)) }

    const [directFlight, setDirectFlight] = useState(false);
    const [flexFlight, setFlexFlight] = useState(false);

    // Options for the dropdowns
    const roundTripOptions = ['Oneway', 'Round Trip', 'Multi - City'];

    const classOptions = ['Any Class', 'Economy', 'Premium Economy', 'Business', 'First Class'];

    // Function to handle when an option is selected
    const handleOptionSelect = (key, option) => {
        setSelectedOptions(({
            ...selectedOptions,
            [key]: option,
        }));

        setStoreData({
            ...selectedOptions,
            [key]: option,
        })
    };

    const { roundTrip = "" } = useSelector(state => state?.homePage?.selectedOptions)
    return (
        <div className='flex justify-between w-full '>
            <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="flex flex-wrap items-center justify-start w-full gap-4 pl-8 bg-white border-gray-300 md:gap-9"
            >
                <CustomFlightDropdown
                    options={roundTripOptions}
                    selectedOption={selectedOptions.roundTrip}
                    onOptionSelect={(option) => handleOptionSelect('roundTrip', option)}
                />
                <CustomFlightDropdown
                    options={airlines}
                    selectedOption={selectedOptions.allAirlines}
                    onOptionSelect={(option) => handleOptionSelect('allAirlines', option)}
                />
                <CustomFlightDropdown
                    options={classOptions}
                    selectedOption={selectedOptions.anyClass}
                    onOptionSelect={(option) => handleOptionSelect('anyClass', option)}
                />

                <FlightCheckbox
                    id="directFlight"
                    label="Direct Flight"
                    checked={directFlight}
                    onChange={() => setDirectFlight(!directFlight)}
                />
                <FlightCheckbox
                    id="flexFlight"
                    label="Flex (+/-3 days)"
                    checked={flexFlight}
                    onChange={() => setFlexFlight(!flexFlight)}
                />
            </motion.div>
            {roundTrip === "Multi - City" && <div className='px-5'>
                <button
                    // onClick={toggleDropdown}
                    className="flex whitespace-nowrap text-[13px] items-center justify-between w-full font-semibold bg-white gap-0.5 text-primary"
                >
                    {"ADD"} <span className='font-extrabold'>
                        +
                    </span>

                </button>

            </div>}
        </div>
    );
};

export default FlightSelectBarFilters;

import React from 'react';
import ImageSlider from '../../../common/ImageSlider';
import LogoSlider from '../../../common/LogoSlider';
import SectionHeader from '../../../common/SectionHeader';
import FlightCardGrid from './FlightCardGrid';
import FlightSearch from './FlightSearch';
import InfoBanner from './InfoBanner';
import { motion } from "framer-motion"
import CookieConsentBanner from '../../../common/CookieConsentBanner';

const FlightHomePage = () => {
    const images = [
        "image-1.png",
        "image-2.png"
    ];

    const logos = [
        "air-logo-1.svg",
        "air-logo-2.svg",
        "air-logo-3.svg",
        "air-logo-4.svg",
        "air-logo-5.svg",
        "air-logo-1.svg",
        "air-logo-2.svg",
        "air-logo-3.svg",
        "air-logo-4.svg",
        "air-logo-5.svg",
        "air-logo-1.svg",
        "air-logo-2.svg",
        "air-logo-3.svg",
        "air-logo-4.svg",
        "air-logo-5.svg",

    ]

    return (
        <div className=''>
            <div className='h-full max-h-[58250px]'>
            </div>
            <div className='relative'>
                <ImageSlider images={images} />
                <div className='absolute z-[10] w-full text-center bottom-16'>
                    <FlightSearch />
                </div>
            </div>

            <div className='py-5'>
                <InfoBanner />
            </div>
            <div className="flex flex-col items-center justify-center w-full gap-6 py-8 "
                style={{ background: 'linear-gradient(to bottom, #FFFFFF 0%, #87D0E7 42%, #9ED9EC 62%, #FFFFFF 100%)' }}>
                <SectionHeader
                    title="Top Searched Destinations"
                    subtitle="Favorite destinations of professional tourists"
                />
                <FlightCardGrid />
            </div>

            <div className=''>
                <SectionHeader
                    title="Popular Airlines"
                    subtitle=""
                />
                <LogoSlider logos={logos} />
            </div>

            <motion.h2
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1.5 }}
                className='pb-2 text-sm font-thin text-center text-gray-400'>
                * Offer valid for selected dates | Subject to Availability | Terms and Conditions Apply
            </motion.h2>
            <CookieConsentBanner />
        </div >
    )
}

export default FlightHomePage
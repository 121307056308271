import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { Button } from '@mui/material';
import { motion } from 'framer-motion';
import React, { useState, useEffect, useRef } from 'react';
import AirportDropdown from './AirportDropdown';
import TravellerSelector from './TravellerSelector';
import FlightSelectBarFilters from './FlightSelectBarFilters';
import DateRangePickerComponent from '../../../common/DateRangePickerComponent';
import { useNavigate } from 'react-router-dom';
import { fetchAirlineConfig } from '../../../../api/airApi';


const FlightSearchFloatingFilter = ({ isSecondary = false, onClick = () => { } }) => {
    const [selection, setSelection] = useState({ from: { code: 'BOM', city: 'Mumbai', name: 'Chhatrapati Shivaji International Airport', country: 'India'}, to: { code: 'LHR', city: 'London', name: 'Heathrow Airport', country: 'United Kingdom' } });
    const [dropdownOpen, setDropdownOpen] = useState({ from: false, to: false });
    const [searchQuery, setSearchQuery] = useState({ from: 'BOM', to: 'LHR' });
    console.log("🚀 ~ FlightSearchFloatingFilter ~ searchQuery:", searchQuery)
    const [isSwapped, setIsSwapped] = useState(false);
    const [airports, setAirports] = useState([]);
    const [airlines, setAirlines] = useState(['All Airlines',]);
    const nextInputRef = useRef(null);

    console.log("🚀 ~ FlightSearchFloatingFilter ~ selection:", selection)

    useEffect(() => {
        const loadAirlineCode = async () => {
            try {
                const data = await fetchAirlineConfig('AIRLINE_CODE');
                // Separate and format the airline and airport data
                const airlineData = data.map((item) => (item.Airlinename)) || [];
                setAirlines(airlineData);
            } catch (error) {
                console.error("Error loading airline configuration data:", error);
            }
        };

        const loadAirportCode = async () => {
            try {
                const data = await fetchAirlineConfig('AIRPORT_CODE');
                const airportData = data
                    .map((item) => ({
                        code: item.AirportCode,
                        name: item.AirportName,
                        city: item.City,
                        country: item.Country,
                    })) || [];
                setAirports(airportData);
            } catch (error) {
                console.error("Error loading airport configuration data:", error);
            }
        };
        loadAirportCode();
        loadAirlineCode();
    }, []);

    const toggleDropdown = (field) => {
        setDropdownOpen((prev) => ({
         [field]: !prev[field],
        }));
        filteredAirports(field);

    };

    const handleSearch = (e, field) => {
        setSearchQuery((prev) => ({
            ...prev,
            [field]: e.target.value,
        }));
        setDropdownOpen((prev) => ({
            ...prev,
            [field]: true,
        }));
    };

    const handleSelect = (field, airport) => {
        setSelection((prev) => ({
            ...prev,
            [field]: airport,
        }));
        setDropdownOpen((prev) => ({
            ...prev,
            [field]: false,
            ...(field === 'from' && { to: true }), // Open 'to' dropdown if field is 'from'
        }));
        setSearchQuery((prev) => ({
            ...prev,
            [field]: airport.code,
        }));
        nextInputRef.current.focus();
    };

    const swapFields = () => {
        setSelection({
            from: selection.to,
            to: selection.from,
        });
        setIsSwapped(!isSwapped);
    };

    const filteredAirports = (field) => {
        return airports.filter((airport) =>
            airport.city.toLowerCase().includes(searchQuery[field].toLowerCase()) ||
            airport.code.toLowerCase().includes(searchQuery[field].toLowerCase())
        ).sort((a, b) => {
            // Sort by matching code first
            if (a.code.toLowerCase().startsWith(searchQuery[field].toLowerCase())) return -1;
            if (b.code.toLowerCase().startsWith(searchQuery[field].toLowerCase())) return 1;
            // If neither matches, sort alphabetically by code
            return a.code.localeCompare(b.code);
        });
    };
    const navigate = useNavigate();
    const handleSubmit = () => {
        if (isSecondary) {
            onClick()
        } else {
            navigate("/flight-filter")
        }

    }

    return (
        <div className="flex items-stretch w-full h-full gap-3 bg-white shadow-md rounded-tr-md rounded-b-md">
            <div className='flex flex-col items-end justify-between w-full p-2 lg:p-0 md:flex-row'>
                <div className='flex flex-col w-full'>
                    <div className='flex flex-col items-center justify-center w-full md:flex-row'>
                        <div className="relative flex flex-col items-center justify-between w-full gap-3 sm:flex-row">
                            <div className="flex-grow px-3 sm:pr-2">
                                <AirportDropdown
                                    label="Flying From"
                                    iconType="takeoff"
                                    selectedAirport={selection.from}
                                    searchQuery={searchQuery.from}
                                    airports={filteredAirports('from')}
                                    onSearchChange={(e) => handleSearch(e, 'from')}
                                    onSelect={(airport) => handleSelect('from', airport)}
                                    dropdownOpen={dropdownOpen.from}
                                    toggleDropdown={() => toggleDropdown('from')}
                                />
                            </div>

                            <div className="flex-shrink-0 px-2 ">

                                <button
                                    className="relative z-10 p-1 transition-all bg-white border rounded-full border-primary group hover:bg-primary hover:text-white"
                                    onClick={swapFields}
                                >
                                    <motion.div
                                        animate={{ rotate: isSwapped ? 180 : 0 }}
                                        transition={{ duration: 0.5 }}
                                    >
                                        <SwapHorizIcon className="text-gray-500 group-hover:text-white" />
                                    </motion.div>
                                </button>
                            </div>

                            <div className="flex-grow px-3 sm:pl-2">
                                <AirportDropdown
                                    ref={nextInputRef}
                                    label="Flying To"
                                    iconType="land"
                                    selectedAirport={selection.to}
                                    searchQuery={searchQuery.to}
                                    airports={filteredAirports('to')}
                                    onSearchChange={(e) => handleSearch(e, 'to')}
                                    onSelect={(airport) => handleSelect('to', airport)}
                                    dropdownOpen={dropdownOpen.to}
                                    toggleDropdown={() => toggleDropdown('to')}
                                />
                            </div>
                        </div>
                        <div className="hidden border-l border-gray-300 md:block h-14" />
                        <div className='flex flex-col items-center justify-center w-full gap-2 pl-3 md:justify-between md:flex-row'>
                            <DateRangePickerComponent />
                            <div className="hidden border-l border-gray-300 md:block h-14" />

                            <TravellerSelector />
                        </div>
                    </div>
                    {!isSecondary && <div className='border-t'>
                        <FlightSelectBarFilters airlines={airlines} />
                    </div>}

                </div>
                <Button
                    variant="contained"
                    onClick={handleSubmit}
                    className='w-full !rounded-r-md !rounded-none h-full bg-primary font-bold text-white text-3xl  max-w-[140px]'
                >
                    {isSecondary ? "Modify" : "Search"}
                </Button>
            </div>

        </div>
    );
};

export default FlightSearchFloatingFilter;

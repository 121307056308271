import { useState } from 'react';
import { motion } from 'framer-motion';

const CookieConsentBanner = () => {
    const [showBanner, setShowBanner] = useState(true);

    const handleAccept = () => {
        setShowBanner(false);
    };

    return (
        <>
            {showBanner && (
                <motion.div
                    initial={{ y: 100, opacity: 0 }} // Start off-screen
                    animate={{ y: 0, opacity: 1 }}    // Animate into view
                    exit={{ y: 100, opacity: 0 }}     // Animate out of view
                    transition={{ duration: 0.5 }}    // Duration of the animation
                    className="fixed -bottom-1 left-0 w-full bg-black bg-opacity-70 text-white py-2 px-8 z-50"
                >
                    <div className="flex flex-col gap-3 md:gap-0 md:flex-row md:items-center justify-between">
                        <p className="text-sm">
                            By using our website, you are consenting to our use of cookies in accordance with our Cookies Policy.
                        </p>
                        <button
                            onClick={handleAccept}
                            className="bg-primary text-white px-6 py-0 rounded-sm hover:bg-blue-600 whitespace-nowrap transition text-sm"
                        >
                            Got it!
                        </button>
                    </div>
                </motion.div>
            )}
        </>
    );
};

export default CookieConsentBanner;

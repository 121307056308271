import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward'
import { motion } from "framer-motion"
import { FaChevronDown } from "react-icons/fa"
import StopInfo from "./StopInfo"
import Tooltip from 'rc-tooltip'
import PriceBreakdown from './PriceBreakdown'
import FlightDetails from './FlightDetails'
import { Modal } from "react-responsive-modal";
import "rc-tooltip/assets/bootstrap.css";
import "react-responsive-modal/styles.css";
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default function FlightBookingCard({ visible: isCardVisible }) {
    const [open, setOpen] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);
    const navigate = useNavigate();
    return (
        <div className="flex items-center justify-center pt-3">
            <motion.div
                initial={{ opacity: 0, y: -20 }} // Start position and opacity
                animate={isCardVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: -20 }} // Animate based on visibility
                transition={{ duration: 0.5 }}
                className="relative w-full p-3 pl-6 overflow-hidden bg-white border rounded-md shadow-lg"
            >
                <div className="flex justify-between">
                    <div className="relative flex flex-col w-full gap-4 border-r-2">
                        {[1, 2].map((index) => (
                            <div key={index} className="grid grid-cols-8 space-y-4 sm:flex-row sm:items-center sm:space-y-0">
                                <div className="flex items-center space-x-4">
                                    <img src="/images/logo/filters/ai.svg" alt="Air India logo" className="object-contain w-6 h-6" />
                                    <div>
                                        <h3 className="text-[13px] text-dark font-medium">Air India</h3>
                                    </div>
                                </div>
                                <div className='col-span-2'>
                                    <div className="flex items-end space-x-2 text-sm">
                                        <span className="text-base font-extrabold text-black font-lato">16:25</span>
                                        <span className="text-sm font-medium text-[#575757]">Sun, 22 Sep</span>
                                    </div>
                                    <div className="text-sm font-medium text-[#575757]">LHR - London Heathrow</div>
                                </div>
                                <div className="relative flex items-center justify-center col-span-2 space-x-4 -translate-x-8 ">
                                    <StopInfo />
                                </div>
                                <div className='col-span-2'>
                                    <div className="flex items-end space-x-2">
                                        <span className="text-base font-extrabold text-black font-lato">16:25</span>
                                        <span className="text-sm font-medium text-[#575757]">Sun, 22 Sep</span>
                                    </div>
                                    <div className="text-sm font-medium text-[#575757]">LHR - London Heathrow</div>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <img src="/images/logo/filters/bag.svg" alt="Baggage icon" className="object-contain w-4 h-6" />
                                    <span className="text-xs font-medium text-[#575756]">2 PC</span>
                                </div>
                            </div>
                        ))}
                        <div className="text-[#575756] absolute -bottom-1 right-2 flex items-center gap-2 text-[11px] cursor-pointer" onClick={onOpenModal}>
                            <img
                                src={"images/logo/filters/edit.svg"}
                                alt={"info"}
                                className="object-contain cursor-pointer h-[12px] w-[12px]"

                            />
                            Flights Details
                        </div>
                    </div>
                    <div className="w-full flex-col justify-end flex items-center max-w-[200px]">
                        <div className="flex flex-col items-center justify-center">
                            <div className="flex items-center space-x-2">
                                <span className="text-[20px] text-black font-bold">£ 1,500.20</span>
                                <Tooltip
                                    animation="zoom"
                                    // trigger={['click']}
                                    overlay={<PriceBreakdown />}
                                    placement="rightTop"


                                    arrowContent={<div className="" />}
                                >
                                    <img
                                        src={"images/logo/filters/info.svg"}
                                        alt={"info"}
                                        className="object-contain cursor-pointer h-[12px] w-[12px]"
                                    />
                                </Tooltip>
                            </div>
                            <div className="flex flex-col items-center space-y-2">
                                <span className="text-[10px] text-[#575756]">Including Taxes & Fees</span>
                                <motion.button
                                    onClick={() => navigate("/flight-payment")}
                                    whileTap={{ scale: 0.95 }}
                                    className="h-[30px] relative flex gap-1 w-[120px] items-center text-center justify-center px-2 font-medium text-white bg-primary rounded-md"
                                >
                                    Book
                                    <ArrowOutwardIcon className="absolute right-0 text-white" />
                                </motion.button>
                            </div>
                        </div>
                        <motion.div
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: "auto" }}
                            transition={{ duration: 0.3 }}
                            className="flex items-center justify-between pt-2 cursor-pointer text-primary"
                        >
                            <FaChevronDown size={20} />
                        </motion.div>
                    </div>
                </div>
            </motion.div>
            <Modal
                open={open}
                onClose={onCloseModal}
                center

                styles={{
                    modal: {
                        minWidth: "900px",
                        maxWidth: "100%",
                        width: "60%",
                        borderRadius: "12px"
                    },
                }}
            >
                {/* Close button is automatically included by react-responsive-modal */}
                <div className='pt-6'>
                    <FlightDetails /> {/* Display your FlightDetails component */}
                </div>
            </Modal>
        </div>
    )
}

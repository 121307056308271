import { ChevronRight, Facebook, Instagram, Twitter, WhatsApp } from '@mui/icons-material'
import { motion } from 'framer-motion'
import React from 'react'
import ContactCard from './home/ContactCard'
import { NavLink } from 'react-router-dom'

const socialIcons = [
    { Icon: Facebook, href: '#' },
    { Icon: Instagram, href: '#' },
    { Icon: Twitter, href: '#' },
    { Icon: WhatsApp, href: '#' },
]

const companyLinks = [
    { name: 'About Us', href: '/about' },
    { name: 'Our Services', href: '/services' },
    { name: 'Contact Us', href: '/contact' },
    { name: 'FAQ', href: '/faq' },
]

const technologyPartners = [
    { name: 'Partner 1', logo: '/images/footer-logo-1.png' },
    { name: 'Partner 2', logo: '/images/footer-logo-2.png' },
    { name: 'Partner 3', logo: '/images/footer-logo-3.svg' },
]

const Footer = () => {
    return (
        <footer className="py-2 text-white bg-primary">
            <div className="px-4 mx-auto">
                <div className='flex justify-end py-3 border-b-[0.5px] border-gray-300  w-full'>

                    <div className='flex items-center text-[15px] justify-between gap-2 container max-w-[1312px] mx-auto'>
                        <NavLink
                            to={"/"}
                            className={"text-lg font-normal text-white sm:text-[18px] md:text-4xl"}
                        >

                            TravelTix
                        </NavLink>

                        <div className='flex items-center text-[15px] justify-end gap-2 container max-w-[1312px] mx-auto'>
                            <img
                                src={"/images/logo/watsapp-footer.svg"}
                                alt={"watsapp-footer"}
                                className="object-contain h-[22px] w-[22px]"
                            />
                            Need help? Call us
                            <span className='text-[16px] px-2'>
                                +44 (0) 7916325248
                            </span>
                        </div>
                    </div>
                </div>
                <div className='pt-10 pb-4 flex flex-col items-start gap-8 container max-w-[1312px] mx-auto'>
                    <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5 }}
                            className="space-y-7"
                        >
                            <h3 className="font-bold text-md md:text-[16px] sm:text-[16px]">Stay Connected</h3>
                            <div
                                className="space-y-2"
                            >
                                <p className="flex items-center text-xs md:text-sm sm:text-[14px]">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="flex-shrink-0 w-5 h-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                                    </svg>
                                    Sandal Avenue, LE4 5HZ Leicester, UK
                                </p>
                                <p className="flex items-center text-sm sm:text-[14px]">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="flex-shrink-0 w-5 h-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                                    </svg>
                                    Open 7 days a week
                                </p>
                                <p className="text-xs ml-7 sm:text-[14px]">(08:00 am - 08:00 pm)</p>
                                <p className="text-xs md:text-[12px] ml-7">Office Visit Hours By Appointment Only</p>
                                <p className="flex items-center text-xs md:text-sm sm:text-[14px]">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="flex-shrink-0 w-5 h-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                        <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                    </svg>
                                    sales@nilumtravel.co.uk
                                </p>

                            </div>
                        </motion.div>

                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.2 }}
                            className="space-y-7 pl-7"
                        >
                            <h3 className="text-sm font-bold sm:text-base">Company</h3>
                            <ul className="space-y-3 ">
                                {companyLinks.map((link, index) => (
                                    <li key={index} className="flex items-center text-xs md:text-sm sm:text-base ">
                                        <ChevronRight className="w-5 h-5 mr-2" />

                                        <a href={link.href} className="text-sm hover:underline">
                                            {link.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </motion.div>

                        <motion.div
                            initial={{ opacity: 0, y: 20, x: -50 }}
                            animate={{ opacity: 1, y: 0, x: -50 }}
                            transition={{ duration: 0.5, delay: 0.4 }}
                            className="space-y-7 "
                        >
                            <h3 className="text-sm font-bold sm:text-base ">Need Anything?</h3>
                            <div className="space-y-2 ">
                                <div>
                                    <ContactCard type="footer" />
                                </div>
                                <p className="text-[12px] sm:text-sm">Follow us</p>
                                <div className="flex space-x-4">
                                    {socialIcons.map(({ Icon, href }, index) => (
                                        <motion.a
                                            key={index}
                                            href={href}
                                            whileHover={{ scale: 1.1 }}
                                            whileTap={{ scale: 0.9 }}
                                            className="p-2 bg-white rounded-full text-sky-500"
                                        >
                                            <Icon />
                                        </motion.a>
                                    ))}
                                </div>
                            </div>
                        </motion.div>

                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.6 }}
                            className="space-y-7"
                        >
                            <h3 className="text-sm font-bold sm:text-base">Our Technology Partners</h3>
                            <div className="grid grid-cols-1 gap-2 space-y-2 sm:grid-cols-1">
                                {technologyPartners.map((partner, index) => (
                                    <img
                                        key={index}
                                        src={partner.logo}
                                        alt={partner.name}
                                        className="object-contain h-[32px] "
                                    />
                                ))}
                            </div>
                        </motion.div>
                    </div>
                    <p className="text-[10px] leading-4">
                        Flydiu,co.uk  is a leading travel brand under trading name of NILKANTH TRAVE LTD ( Company Reg No.: 09775713,  IATA Certified ).
                    </p>
                </div>
                <div className='flex justify-end py-3 border-t-[0.5px] border-gray-300  w-full'>
                    <div className='contaier flex justify-center mx-auto text-center text-[14px]'>
                        Copyright © 2024 Nilkanth Travel ltd. All rights reserved.
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer

import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';

const CommonModal = ({ isOpen, onClose, children }) => {
    // Animation variants for modal drawer open/close
    const drawerVariants = {
        hidden: { y: 80, opacity: 0 },
        visible: { y: 80, opacity: 1 },
        exit: { y: 80, opacity: 0 },
    };

    // Function to detect clicks outside of modal content
    const handleOutsideClick = (e) => {
        if (e.target.id === 'modal-overlay') {
            onClose();
        }
    };

    return (
        <AnimatePresence>
            {isOpen && (
                <div
                    id="modal-overlay"
                    className="fixed inset-0 z-50 flex items-start justify-center bg-gray-800 bg-opacity-50"
                    onClick={handleOutsideClick}
                >
                    {/* Drawer content */}
                    <motion.div
                        className="relative rounded-lg max-w-[1312px] w-full bg-white  shadow-lg"
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        variants={drawerVariants}
                        transition={{ type: 'spring', stiffness: 100, damping: 20, duration: 0.5 }}
                        onClick={(e) => e.stopPropagation()} // Prevent clicks inside modal from closing it
                    >
                        {/* Children content */}
                        <div>{children}</div>
                    </motion.div>
                </div>
            )}
        </AnimatePresence>
    );
};

export default CommonModal;

"use client";

import { motion } from 'framer-motion';
import React, { useState } from 'react';
import FlightSearchFloatingFilter from './FlightSearchFloatingFilter';

export default function FlightSearch() {
    const [activeTab, setActiveTab] = useState("Flights");

    const tabItems = [
        { name: "Flights", icon: "flight.svg" },
        { name: "Hotel", icon: "hotel2.svg" },
        { name: "My Trip", icon: "trip.svg" }
    ];

    const getButtonClasses = (index) => {
        if (index === 0) {
            return "rounded-tl-md";
        } else if (index === tabItems.length - 1) {
            return "rounded-tr-md  ";
        } else {
            return "border-l-0 ";
        }
    };

    return (
        <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="w-full max-w-[1312px] flex flex-col mx-auto"
        >
            <div className="flex ">
                {tabItems.map((tab, index) => (
                    <button
                        key={tab.name}
                        disabled={index !== 0}
                        onClick={() => setActiveTab(tab.name)}
                        className={`flex items-center px-10 py-2 space-x-2  ${activeTab !== tab.name
                            ? "bg-primary text-white font-semibold"
                            : "text-gray-500 bg-white"
                            } transition-colors duration-300 ease-in-out ${getButtonClasses(index)}`}
                    >
                        <img className='w-5 h-5' src={`images/logo/${tab?.icon}`} alt={tab?.icon} />
                        <span className='hidden md:block'>{tab.name}</span>
                    </button>
                ))}
            </div>
            {activeTab === "Flights" ?
                <FlightSearchFloatingFilter />
                : <></>}
        </motion.div>
    );
}

import { motion } from "framer-motion";
import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import FlightDetails from '../../flightFilter/FlightDetails';
import FlightFinalCard from './FlightFinalCard';

const PaymentFinalCard = () => {
    const [isOpen, setIsOpen] = useState(false);

    // Toggle the state
    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };
    return (
        <div className="border border-gray-200 rounded-lg shadow-sm ">
            <div className="flex items-center justify-between p-2 ">
                <h2 className="text-lg font-semibold">Review your booking</h2>
                {/* <div className='flex items-center gap-2'>
                    <span className="text-sm text-gray-500 cursor-pointer">Flights Details</span>

                    <Tooltip

                        animation="zoom"
                        overlay={<FlightDetails />}
                        placement="bottom"
                        trigger={['click']}
                        arrowContent={<div className="" />}
                    >
                        <FaChevronDown className='text-primary' size={20} />
                    </Tooltip>
                </div> */}

                <div>
                    {/* Accordion Header */}
                    <div className="flex items-center gap-2 cursor-pointer" onClick={toggleAccordion}>
                        <span className="text-sm text-gray-500 select-none">Flights Details</span>

                        <motion.div
                            animate={{ rotate: isOpen ? 180 : 0 }}
                            transition={{ duration: 0.3 }}
                        >
                            {isOpen ? <FaChevronUp className="rotate-180 text-primary" size={20} /> : <FaChevronDown className="text-primary" size={20} />}
                        </motion.div>
                    </div>


                    {/* <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: isOpen ? 'auto' : 0, opacity: isOpen ? 1 : 0 }}
                        transition={{ duration: 0.3, ease: 'easeInOut' }}
                        className="overflow-hidden"
                    >
                        {isOpen && <FlightDetails />} 
                    </motion.div> */}
                </div>


            </div>
            <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: isOpen ? 'auto' : 0, opacity: isOpen ? 1 : 0 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: 0.3, ease: 'easeInOut' }}
                className="overflow-hidden"
            >
                {isOpen && <FlightDetails />}
            </motion.div>

            {!isOpen && <div className="flex p-2  bg-[#f7f7f7] space-x-6">

                <FlightFinalCard isBorder />
                <FlightFinalCard />

            </div>}
        </div>
    );
};

export default PaymentFinalCard;
